import React from 'react'
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AppName } from '../constants/global'
import LegalMenu from './components/legal-menu';

const LegalTermsOfService = () => {

  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myQueryParam = urlParams.get('fbw');
    setShowComponent(myQueryParam === 'true');
  }, []);


  return (
<div className='PageName'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - תנאי השירות</title>
        <meta name="description" content="" />
</Helmet>
{/* content goes here */}


<main className="page lanidng-page">
<div>
  <section className="portfolio-block block-intro" style={{marginTop: '-4px', paddingTop: 57, paddingBottom: 57, width: '100%', paddingLeft: 0}}><img alt="" src="/assets/img/script.png" style={{width: 160, marginBottom: 15}} />
    <div className="container" style={{paddingLeft: 10, paddingRight: 10}}>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col">
          <h1 style={{color: 'rgb(40,40,40)', fontSize: 45}}><strong>תנאי שימוש</strong></h1>
        </div>
      </div>
    </div>
  </section>
  <section className="photo-gallery" />
  <section className="portfolio-block call-to-action border-bottom" style={{width: '100%', paddingTop: 40, paddingBottom: 40, marginTop: '-9px'}}>
    <div className="container" style={{marginBottom: 0}}>
      <section className="features-boxed" style={{background: 'rgba(238,244,247,0)'}}>
        <div className="container">
          <div className="intro" />
          <div className="row justify-content-center features" style={{paddingBottom: 0, paddingTop: 0, background: 'rgba(255,255,255,0)'}}>
            <LegalMenu/>
            <div className="col-xl-10" style={{color: '#000000!important'}}>
              <hr />
                <p>שמחים שלקחתם רגע בשביל לעבור על תנאי השירות שלנו.</p>
                <p>תנאי השירות החלים על השירותים אותם אנו מספקים. מסמך זה מתעדכן מעת לעת, עליכם לבדוק כאן ולהתעדכן לגבי כל שינוי באשר הוא.</p>
                <p>תאריך עדכון אחרון: 10/12/2022.</p>
                <p>יתכן וחלק מהאמור בתקנון זה יופיע בלשון זכר, התקנון מתייחס לבני שני המינים באופן שווה.</p>
                <p><br /></p>
                <p><strong><span>הקדמה:</span></strong></p>
                <ol>
                {showComponent && (
                  <li>למה לינק הינו מוצר מבית העסק אושר אלבז&nbsp;<br /><br /></li>
                  )}
                  <li>העסק למה לינק, מספר:. ("העסק"', "אנחנו", "אנו") מפעיל מערכת המאפשרת הקמה ואירוח אתרי אינטרנט, הקמת שרתים וירטואלים פרטיים, קבלה והגדרה של כתובות דוא"ל עסקיות המפנות לכתובות דוא"ל אחרות,&nbsp;<br /><br /></li>
                  <li>אין בתנאי השימוש בכדי לגרוע או לשנות מכל הסכם אחר בין המשתמש לבין העסק. בכל מקרה של סתירה בין תנאי השימוש לבין מסמכים פרטניים אחרים, יגבר האמור במסמכים האחרים, אלא אם צוין אחרת. מבלי לגרוע מהאמור לעיל, בכל מקרה של סתירה או אי התאמה בין תנאי השימוש לבין תנאים או הוראות המפורסמים בכל אמצעי פרסום אחרים, יגברו הוראות תנאי השימוש.<br /><br /></li>
                  <li>כל אכיפה או טיפול בנושא הנוגע לתקנון זה יעשה אך ורק בהתאם לדין הישראלי והמוסמכים לטפל בדין זה הם בתי המשפט בישראל בלבד.<br /><br /></li>
                  <li>כל הפרה של הכתוב בהסכם זה תשמש עילה לסגירה מיידית של השירות/המוצר/החשבון השיך למשתמש המפר.<br /><br /></li>
                  <li>כל רכישה באמצעות אתר זה או כלל האתרים היושבים תחתיו כפופים למדיניות ההסכם הנ"ל.</li>
                </ol>
                <p><br /></p>
                <p><strong><span>הגדרות כלליות:</span></strong></p>
                <ol data-v-509c764b>
                  <li data-v-509c764b><strong data-v-509c764b>"הפלטפורמה"</strong> או <strong data-v-509c764b>"האתר" או "מוצר" או "מוצרים"</strong> - המערכת באתר זה האפשרת הקמה ואירוח אתרים, שרתים וירטואלים פרטיים וכלל שירותים עסקיים אחרים וכלל שירותי העסק.</li>
                  <li data-v-509c764b><strong data-v-509c764b>"משתמש"</strong> או <strong data-v-509c764b>"משתמשים"</strong> או <strong data-v-509c764b>"לקוח"</strong> - כל מי שעושה שימוש כלשהו בפלטפורמה או באתר העסק.</li>
                </ol>
                <p><br /></p>
                <p><strong><span>רכישה:</span></strong></p>
                <ol>
                  <li>הפלטפורמה מאפשרת למשתמשים לרכוש בקלות ובמהירות מגוון מוצרים באמצעות סליקה באינטרנט.<br /><br /></li>
                  <li>הרכישה באתר היא באמצעות כרטיס אשראי בלבד, יתכנו מצבים בהם נקבל תשלום באמצעים אחרים אך דבר זה תלוי בתיאום מראש עם צוות התמיכה.<br /><br /></li>
                  <li>המוצר מדווח ונמכר כחוק ובתום כל רכישה המשתמשים יקבלו קבלה חתומה דיגיטלית על העסקה אותה ביצעו.<br /><br /></li>
                  <li>העסק שומר לעצמו את הזכות לעכב או לבטל את הרכישה בכל עת.<br /><br /></li>
                  <li>העסק שומר לעצמו את הזכות לעצור כל מוצר בשימוש המשתמשים כאשר מועד התשלום חרג בכחודש ממועד החיוב על המוצר.&nbsp;<br /><br /></li>
                  <li>העסק שומר לעצמו את הזכות שלא לספק מוצר ללקוחות שהורחקו(כגון לקוחות שצברו אגרת חוב ו-או ביצעו-או עלולים לבצע פעולות עויינות כנגד הפלטפורמה ומוצריה)<br /><br /></li>
                  <li>העסק רשאי לבטל את רישומו של גולש לרשימת הדיוור לפי שיקול דעתו המוחלט. מבלי לגרוע מהאמור לעיל, רשאי האתר למנוע גלישת גולש ו/או לבטל רישום לרשימת הדיוור, או לחסום גישה אליו או למערכת האתר והמוצרים בכלל, בכל אחד מהמקרים הבאים:
                  <br /><br />
                      <ul>
                          <li>אם בעת השארת הפרטים ו/או הרכישה באתר נמסרו במתכוון פרטים שגויים;</li>
                          <li>במקרה שנעשה שימוש באתר לביצוע או כדי לנסות לבצע מעשה בלתי חוקי על-פי דיני מדינת ישראל, או מעשה הנחזה על פניו כבלתי חוקי כאמור, או כדי לאפשר, להקל, לסייע או לעודד ביצועו של מעשה כזה;</li>
                          <li>אם הופרו תנאי התקנון;</li>
                          <li>אם נעשתה על ידי גולש כל פעולה שתמנע מאחרים להמשיך ולהשתמש באתר בכל דרך שהיא.</li>
                      </ul>
                  <br />
                  </li>
                  <li>הרכישה תתאפשר אך ורק מגיל 18 ומעלה.<br /><br /></li>
                  <li>בעת רכישת מוצר באתר המוצר יסופק ללקוח באופן מידי דרך מערכת האתר ויהיה זמין בפאנל הלקוחות שלנו.<br /><br /></li>
                  <li>מאחר והשירות הינו שירות וירטואלי המסופק ישר מרגע הרכישה לא ניתן לבטל את הרכישה ולקבל החזר כספי.</li>
                </ol>
                <p><br /></p>
                <p><strong><span>שימוש בפלטפורמה לרעה:</span></strong></p>
                <ol>
                  <li>כל שימוש בפלטפורמה לצורך סריקה, נסיון גישה למידע שאינו שיך למשתמש ו-או בדרך שאינה כמוסכם, נסיון פריצה, נסיון העלאת "<a href="https://www.isoc.org.il/netica/question-and-answers/technology-and-filtering-software/offensive-content" rel="noopener noreferrer" target="_blank">תכנים אסורים ברשת האינטרנט</a>" כמוגדרים בחוק, נסיון שיבוש השירות למשתמשים אחרים ו-או נסיון שיבוש שירותים לכל אדם אחר באינטרנט יתלוו בהרחקה לצמיתות מהמערכת ובמקרי הצורך אף בתלונה במשטרה ו-או תביעה בבית משפט.<br /><br /></li>
                  <li>ביצוע פעולות כגון: חציבת מטבעות וירטואלים/קריפטוגרפים, הרצת צומת העברה למטבעות וירטואלים וכל דבר דומה אסורה בהחלט. <br /> משתמש אשר ימצא ומריץ את הנ"ל יורחק לצמיתות ואף במקרה הצורך תוגש נגדו תביעה בבית משפט. &nbsp;<br /><br /></li>
                </ol>
                <p><br /></p>
                <p><strong><span>אבטחת מידע ו<strong>הגנות בסייבר</strong>, אחריות הלקוח על ניהול ושימוש:</span></strong></p>
                <ol>
                  <li>מדיניות ההגנה על המערכות דרכם אנו העסק מספקים ללקוחותינו שירות כוללת אמצעי אבטחה בשכבות שונות, הן בשכבות התוכנה המנהלת את המוצרים אותם אנו מספקים והן בשכבות החומרה והציוד המחברים אותנו לרשת האינטרנט.<br /><br /></li>
                  <li>אנו עושים שימוש בכלל הדרכים בכדי לאפשר הגנה מקסימלית על הנתונים והזמינות של אתרי לקוחותינו: שימוש בגרסאות התוכנה החדישות והיציבות ביותר, סריקות אבטחה מתקדמות באופן תקופתי על מערכות הקבצים ההפעלה והרשתות, שימוש בחומות אש פיזיות ווירטואליות ושלל שיטות הגנה נוספות.<br /><br /></li>
                  <li>חשוב להבהיר כי השימוש אותו אנו עושים באמצעי הגנה המתקדמים בעולם אבטחת המידע אינו בא להחליף את אמצעי הזהירות שעל הלקוח שלנו לנקוט, ומובן כי איננו יכולים לשלוט, להחליט או לפקח על התנהלותו הארגונית של הלקוח.&nbsp;<br /><br /></li>
                  <li>אנו מעניקים ללקוחותנו תשתית לאירוח אתרי אינטרנט, אירוח שרתים וירטואליים פרטיים, אירוח כתובות מייל עסקיות ועוד. נוסף על כך אנו מספקים ממשק דפדפן המאפשר תכונות ניהול מגוונות בקשר לכך. ואילו אתם הלקוחות האחראיים הבלעדיים לאופן השימוש במוצר, לאופן בו האתר שלכם מנוהל, מתוחזק (תוספיו ומרכיביו), מדיניות האבטחה של האתר שלכם, תקנים וסטנדרטים שתחליטו לאמץ, המערכות אליהם האתר מחובר, סוג החומרים שאחסנתם באתר והאחריות החוקית עליהם. דברים אלו אינם חלק ממסגרת השירות שלנו ואינם באחריותנו, על הלקוחות האחריות הבלעדית לכך.</li>
                </ol>
                <p><br /></p>
                <p><strong><span>זמינות המוצרים וצוות התמיכה בהם, גיבוי נתוני המוצרים:</span></strong></p>
                <ol>
                  <li>העסק עושה כל שביכולתו בכדי להיות זמין בהתאם לשעות והימים הכתובים בדף "צרו קשר" שבאתר הראשי שלנו (לא כולל חגים, מועדים וימים שאינם נחשבים כימי עסקים).<br /><br /></li>
                  <li>העסק עושה כל שביכולתו בכדי לאפשר זמינות מלאה של כלל השירותים והמוצרים השונים אותם הוא מספק, ובכלל את הפלטפורמה כשלעצמה.<br /><br /></li>
                  <li>העסק אינו מתחייב לזמינות תמידית של כלל השירותים והמוצרים ואינו מספק פיצוי במידה ומוצר מסויים או כמה אינם עובדים לפרק זמן או עובדים בביצועים שאינם רגילים.<br /><br /></li>
                  <li>העסק מבצע גיבויים לחלק מהמוצרים באופן שבועי או יומי, העסק אינו מתחייב להחזיק בגיבוי של המידע והאחריות לגיבוי מלא של המידע מוטלת על המשתמש בלבד.<br /><br /></li>
                  <li>העסק אינו מתחייב להחזיק מידע מוצרים על מוצרים שמועד התשלום שלהם חרג בכ-2 חודשים מתאריך החיוב, מוצרים בשימוש שלא שולמו בפרק זמן של חודשיים ומעלה יאבדו את נתוניהם. העסק יעשה כל שביכולתו בכדי להתריע למשתמש המאחר בתשלום ולחדש את העסקה או למסור נתונים בפרק הזמן הנ"ל.</li>
                </ol>
                <p><br /></p>
                <p><strong><span>קבלת עדכונים בערוצים השונים ושימוש בערוצי התמיכה:</span></strong></p>
                <ol>
                  <li>&nbsp;בעת הרשמת המשתמש לפלטפורמה המשתמש מאשר לעסק ליצור איתו קשר בכל נושא הנוגע למוצר או השירות אותו הוא רוכש.<br /><br /></li>
                  <li>בעת ההרשמה לשירות עדכוני מצב בישום "וואצאפ" המשתמש מאשר לחברה לשלוח לו מסרונים בנושא השירות והמוצר אותו הוא רוכש וכן הודעות עדכון נוספות הנוגעות לעסק.<br /><br /></li>
                  <li>שימוש המשתמש באפליקציית "WhatsApp" כפופה לתנאי השימוש ולמדיניות הפרטיות של "WhatsApp" כפי שאלה מפורטים וזמינים להלן -&nbsp;<a href="https://www.whatsapp.com/legal/business-terms" rel="noopener noreferrer">Whatsapp Terms</a>. המשתמש יקפיד כי השימוש באפליקציית "WhatsApp" ייעשה בהתאם לתנאי השימוש של אפליקציית "WhatsApp".<br /><br /></li>
                  <li>בעת שימוש בערוצי התמיכה השונים שהעסק מספק המשתמש מסכים למדיניות הפרטיות ותנאי השימוש של אותם ערוצי תמיכה שונים.</li>
                </ol>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</div>

</main>

</div>


  )
}

export default LegalTermsOfService