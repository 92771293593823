import React from 'react';
import { NavLink } from 'react-router-dom';


const today = new Date();
const year = today.getFullYear();


const Footer = () => {
  return (


<>
  <footer className="page-footer" style={{paddingTop: 55, background: '#ffffff', borderColor: 'rgb(90,63,254)', borderTopWidth: '0px!important', borderTopColor: 'rgb(91,113,230)'}}>
    <div className="container">
      <div className="row" style={{marginBottom: 30}}>
        <div className="col-xl-4" style={{marginBottom: 35}}>
          <h1 className="foter-titles"><i className="fa fa-shopping-bag" style={{marginLeft: 5}} /><strong>&nbsp;מוצרים</strong></h1>
          <p className="footer-items-sub"><NavLink to="/websites">אירוח אתרים מתקדם</NavLink></p>
          <p className="footer-items-sub"><NavLink to="/vps">שרתים וירטואלים</NavLink></p>
          <p className="footer-items-sub"><NavLink to="/email">אימייל עסקי</NavLink></p>
          <p className="footer-items-sub"><NavLink to="/contact">עובדים על משהו גדול?</NavLink></p>
        </div>
        <div className="col-xl-4" style={{marginBottom: 35}}>
          <h1 className="foter-titles"><i className="fas fa-life-ring" style={{marginLeft: 5}} /><strong>&nbsp;תמיכה ומידע</strong></h1>
          <p className="footer-items-sub"><a href="https://apbs.link/status" target="_blank" rel="noopener noreferrer"><img alt="" src="/assets/img/webp/uptimerobot-logo.webp" style={{width: 26}} />&nbsp;סטטוס (מצב המערכות שלנו)</a></p>
          <p className="footer-items-sub"><NavLink to="/help">מרכז תמיכה ומידע</NavLink></p>
          <p className="footer-items-sub"><a href="https://www.dmca.com/creator/llamalink" target="_blank" rel="noopener noreferrer">סימן מסחרי</a></p>
          <p className="footer-items-sub"><NavLink to="/legal">מדיניות הפרטיות, תנאי השירות ועוד.</NavLink></p>
        </div>
        <div className="col-xl-4" style={{marginBottom: 35}}>
          <h1 className="foter-titles"><i className="fa fa-building" style={{marginLeft: 5}} /><strong>&nbsp;חברה</strong></h1>
          {/*p class="footer-items-sub"><i class="fa fa-users" style="margin-left: 5px;"></i>&nbsp;<span style="text-decoration: underline;">דרושים</span></p*/}
          <p className="footer-items-sub"><i className="fas fa-phone" style={{marginLeft: 2}} />&nbsp;<a href="/contact"><span style={{textDecoration: 'underline'}}>יצירת קשר</span></a></p>
          <p className="footer-items-sub"><i className="fa fa-map-marker" style={{marginLeft: 6}} />&nbsp;גוגל מפות<a href="https://apbs.link/llamalink-google" style={{marginRight: 4, marginLeft: 4}} target="_blank" rel="noopener noreferrer"><span style={{textDecoration: 'underline'}}>לחצו כאן</span></a></p>
        </div>
      </div>
      <div className="social-icons">
        <a href="https://apbs.link/linkedin" className="socials-item" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" /></a>
        <a href="https://apbs.link/facebook" className="socials-item" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook" /></a>
        <a href="https://apbs.link/instagram" className="socials-item" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
        </div>
    </div><small style={{fontSize: '14.8px'}}>כל הזכויות שמורות ללמה לינק נט LlamaLink.&nbsp;&nbsp;<a href="https://www.dmca.com/Protection/Status.aspx?id=ef4f1907-4287-4730-b658-77cd62daa5f9&refurl=https%3a%2f%2fllamalink.net%2f" style={{fontSize: '14.8px', marginRight: 0, marginLeft: 0}}>(מוגן בזכויות יוצרים)<br /></a>&nbsp;© {year}</small>
  </footer>
  </>
    )
}

export default Footer