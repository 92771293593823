import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa,Keywards,Siteurler,SiteTwitter,SiteImager } from '../constants/global'

const About = () => {

  return (
<div className='About'>
<Helmet>
        <title>{AppName} - אודות</title>

        <meta name="description" content="אודות התשתיות והמערכת שלנו, רוצים לדעת יותר על הבית העתידי של המוצר שלכם? כאן זה המקום" />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- אודות"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="אודות התשתיות והמערכת שלנו, רוצים לדעת יותר על הבית העתידי של המוצר שלכם? כאן זה המקום" /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="אודות התשתיות והמערכת שלנו, רוצים לדעת יותר על הבית העתידי של המוצר שלכם? כאן זה המקום" />
        <meta name="twitter:image" content={SiteImager} />

</Helmet>

  <main data-aos="fade" data-aos-once="true" className="page lanidng-page">
    <div id="id1" data-spy="scroll" />
    <section style={{marginTop: '-9px', paddingTop: 50, paddingBottom: 50, background: '#e5ebff'}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-5">
            <h1 className="text-right" style={{color: 'rgb(30 33 103)', fontSize: 53, textAlign: 'center', marginBottom: 20, fontFamily: 'Heebo, sans-serif', paddingTop: 80}}><strong>אודות למה לינק</strong><span style={{color: '#2a60ff'}}><strong>.</strong></span></h1>
            <h1 className="text-right" style={{textAlign: 'center', color: 'rgb(54 54 54)', fontFamily: 'Heebo, sans-serif', fontSize: 19}}><strong>"</strong>איכות היא לא מעשה, היא הרגל.<strong>"</strong> ~אריסטו
            </h1>
          </div>
          <div className="col-xl-6"><img alt="server farm" style={{width: '100%', marginTop: 40, borderRadius: 11}} className="lazyload" data-src={ AppCDNa + 'assets/img/webp/server-technician-adjusting-wiring-2933400-2438575.webp'}/></div>
        </div>
      </div>
    </section>
    <section style={{paddingTop: 63, paddingBottom: 63, background: '#ffffff'}}>
      <p style={{fontSize: 19, textAlign: 'center', marginBottom: 70, padding: 15}}>אנחנו מאפשרים לעסקים לבנות אתר אינטרנט בקלות, לנהל כתובות דוא"ל עסקיות, להפעיל שרתים וירטואליים ועוד הרבה מעבר. <br /> והכל דרך מקום אחד מהיר וקליל לשימוש.</p>
      <h1 style={{textAlign: 'center', color: 'rgb(62,62,62)', fontFamily: 'Heebo, sans-serif', fontSize: 46, marginBottom: 25, paddingBottom: 10}}>תשתיות <strong>איכותיות.</strong></h1>
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div style={{padding: 23, background: '#ffffff', boxShadow: '0px 0px 20px 1px #c7d4ffad', borderRadius: 10, marginBottom: 15, minHeight: 189}}>
              <div className="row">
                <div className="col-xl-2 offset-xl-0"><i className="fas fa-shield-alt" style={{color: '#2a60ff', fontSize: 40}} /></div>
                <div className="col-xl-10" style={{paddingLeft: 0}}>
                  <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 26, fontFamily: 'Heebo, sans-serif', marginBottom: 0, paddingTop: 4}}><strong>הגנות מתקדמות</strong></p>
                </div>
              </div>
              <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 20, fontFamily: 'Heebo, sans-serif', marginBottom: 0, paddingTop: 10}}>חומות אש, כלי ניטור ושיתוף פעולה עם חברות אבטחת מידע והגנה מDDOS שנותנים ראש שקט.</p>
            </div>
          </div>
          <div className="col-xl-4">
            <div style={{padding: 23, background: '#ffffff', boxShadow: '0px 0px 20px 1px #c7d4ffad', borderRadius: 10, marginBottom: 15, minHeight: 189}}>
              <div className="row">
                <div className="col-xl-2 offset-xl-0"><i className="fas fa-fighter-jet" style={{color: '#2a60ff', fontSize: 40}} /></div>
                <div className="col-xl-10" style={{paddingLeft: 0}}>
                  <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 26, fontFamily: 'Heebo, sans-serif', marginBottom: 0, paddingTop: 4}}><strong>ביצועים גבוהים</strong></p>
                </div>
              </div>
              <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 20, fontFamily: 'Heebo, sans-serif', marginBottom: 0, paddingTop: 10}}>רשתות מהירות, חומרה חדשה, איזון עומסים והגדרה יעילה שיוצרים יחד מהירות אתרים מהגבוהה בעולם.</p>
            </div>
          </div>
          <div className="col-xl-4">
            <div style={{padding: 23, background: '#ffffff', boxShadow: '0px 0px 20px 1px #c7d4ffad', borderRadius: 10, minHeight: 189, marginBottom: 15, marginRight: 0}}>
              <div className="row">
                <div className="col-xl-2"><i className="fas fa-sync" style={{color: '#2a60ff', fontSize: 40}} /></div>
                <div className="col-xl-10" style={{paddingLeft: 0}}>
                  <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 26, fontFamily: 'Heebo, sans-serif', marginBottom: 0, paddingTop: 4}}><strong>גיבוי גיבוי וגיבוי</strong><br /></p>
                </div>
              </div>
              <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 20, fontFamily: 'Heebo, sans-serif', marginBottom: 0, paddingTop: 10}}>גיבוי מידע חומרתי על בסיס RAID, גיבוי מידע בשרתים נפרדים, מערכות לגיבוי חשמל במצבי קיצון.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className style={{width: '100%', paddingBottom: 31, borderWidth: 0, background: '#ffffff'}}>
      <div className="brands" style={{marginTop: 0, paddingTop: 35, paddingBottom: 25, background: '#fff0'}}>
        <h1 style={{textAlign: 'center', color: 'rgb(62,62,62)', fontFamily: 'Heebo, sans-serif', fontSize: 46, marginBottom: 25}}>טכנולוגיה וציוד ממיטב הספקים.</h1>
        <div style={{paddingTop: 0, paddingBottom: 0}}> 
        <img alt="" data-aos="fade-up" data-aos-duration={650} data-aos-once="true" className="lazyload" data-src={AppCDNa + 'assets/img/webp/horizontal-logo-monochromatic-white-730x188.gif'} style={{width: 140}} />
        <img alt="" data-aos="fade-up" data-aos-duration={700} data-aos-delay={150} data-aos-once="true" className="lazyload" data-src={AppCDNa+'assets/img/webp/hp-enterprise.webp'} style={{width: 180}} />
        <img data-aos="fade-up" data-aos-duration={700} data-aos-delay={250} data-aos-once="true" className="lazyload" data-src={AppCDNa +'assets/img/webp/cloudflare.webp'} style={{width: 180}} />
        <img alt="" data-aos="fade-up" data-aos-duration={700} data-aos-delay={350} data-aos-once="true" className="lazyload" data-src={AppCDNa +'assets/img/webp/checkpoint.webp'} style={{width: 180}} />
        <img alt="" data-aos="fade-up" data-aos-duration={700} data-aos-delay={450} data-aos-once="true" className="lazyload" data-src={AppCDNa + 'assets/img/litespeed-logo.svg'} style={{width: 180}} />
        </div>
      </div>
    </section>
    {/* <section className="portfolio-block call-to-action" style={{width: '100%', paddingBottom: 31, borderWidth: 0, marginTop: 0, paddingTop: 50, background: '#ffffff'}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-7"><video width="100%" autoPlay muted loop>
              <source src={AppCDNa+'assets/video/sharat2.mp4'} type="video/mp4" />
            </video></div>
          <div className="col-xl-5 offset-xl-0" data-aos="fade-up" data-aos-duration={500} data-aos-once="true" style={{paddingTop: 0, marginTop: 55}}>
            <h1 style={{textAlign: 'right', color: 'rgb(62,62,62)', fontFamily: 'Heebo, sans-serif', fontSize: 53, marginBottom: 40}}>חומרה <strong>חזקה</strong></h1>
            <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 20, fontFamily: 'Heebo, sans-serif'}}>אנחנו עושים שימוש בחומרה החדשה והמתקדמת בשוק, בשביל מקסימום ביצועים ומינימום עומסים.</p>
          </div>
        </div>
      </div>
    </section> */}
    <section className="portfolio-block call-to-action" style={{width: '100%', paddingBottom: 31, borderWidth: 0, marginTop: 0, paddingTop: 60, background: '#fff'}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-5 offset-xl-0" data-aos="fade-up" data-aos-duration={500} data-aos-once="true" style={{paddingTop: 0, marginTop: 55}}>
            <h1 style={{textAlign: 'right', color: 'rgb(62,62,62)', fontFamily: 'Heebo, sans-serif', fontSize: 53, marginBottom: 40}}>רשתות מתקדמות</h1>
            <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 20, fontFamily: 'Heebo, sans-serif'}}><i className="fas fa-brain" style={{color: '#5367ff'}} />&nbsp;רשתות השרתים שלנו מקושרות לאינטרנט בעזרת ציוד מתקדם וחכם ממיטב חברות אבטחת הרשתות.<br /><br /><i className="fas fa-shield-alt" style={{color: '#5367ff'}} />&nbsp;אנו מספקים הגנות מסוג: IPS, DDOS, Anti-Virus, Threat Emulation, Anti-Bot עוד...<br /><br /><i className="icon ion-ios-speedometer" style={{fontSize: 26, color: '#5367ff'}} />&nbsp;הרשתות מקושרות בחיבור סיב אופטי מהיר כדי למנוע עומסים.<br /><br /><i className="fas fa-network-wired" style={{color: '#5367ff'}} />&nbsp;3 ספקים וסוגי חיבור שונים לאינטרנט המבטיחים זמינות במצבי קיצון.</p>
          </div>
          <div className="col-xl-7"><img alt="server network" data-aos="fade" data-aos-duration={950} data-aos-once="true" style={{width: '100%'}} className="lazyload" data-src={AppCDNa+'assets/img/webp/network_rdy.webp'} /></div>
        </div>
      </div>
    </section>
    {/*section class="portfolio-block call-to-action" style="width: 100%;padding-bottom: 31px;border-width: 0px;margin-top: 0px;padding-top: 50px;background: #ffffff;">
      <div class="container" style="margin-top: 30px;margin-bottom: 30px;">
          <div class="row">
              <div class="col-xl-6 offset-xl-0" data-aos="fade-up" data-aos-duration="500" data-aos-once="true" style="padding-top: 0px;padding-right: 40px;">
                  <h1 style="text-align: right;color: rgb(62,62,62);font-family: Heebo, sans-serif;font-size: 53px;margin-bottom: 40px;"><strong>אבטחה 24/7</strong></h1>
                  <p style="text-align: right;color: rgb(62,62,62);font-size: 20px;font-family: Heebo, sans-serif;">אבטחה סביב השעון ומערך מצלמות אבטחה מבוססות בינה מלאכותית סביב השרתים, התראות בזמן אמת, וצילום 24/7 במעגל סגור.<br></p>
              </div>
              <div class="col-xl-6"><img data-aos="fade" data-aos-duration="950" data-aos-once="true" style="width: 100%;transform: rotateY(180deg);" src="{AppCDN}assets/img/webp/camer_rdy.webp"></div>
          </div>
      </div>
  </section*/}
  </main>
  <section data-bss-parallax-bg="true" className="portfolio-block website gradient" style={{marginTop: '-1px', background: '#ffffff'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-7">
          <h1 style={{textAlign: 'right', color: 'rgb(33,33,33)', fontFamily: 'Heebo, sans-serif', fontSize: 35}}><strong>בקיצור, כזה אתם רוצים <br /></strong>התחילו 15 יום חינם, נסו אותנו<br /></h1>
        </div>
        <div className="col-xl-4" style={{textAlign: 'center'}}><a href="/signup"><button className="btn btn-primary bigbtn" data-bss-hover-animate="pulse" type="button" style={{background: 'linear-gradient(89deg,#4885ff,#5b54ff)', borderRadius: 50, fontSize: 20, textShadow: '0px 0px 8px rgba(0,0,0,0.25)', marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8, textAlign: 'center'}}><strong>התחילו כאן</strong></button></a></div>
      </div>
    </div>
  </section>
</div>


  )
}

export default About