import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName,AppLogo, AppNameEn, AppCDNa, Siteurler,Keywards,SiteTwitter,SiteImager } from '../constants/global'
// import './styles/HomeStyle.css'
// import './scripts/HomeScript'
// import $ from 'jquery';
import { NavLink } from 'react-router-dom';

const BackStyle = `
/*siteback*/
.herosectionarry{
      background: #1a1d78!important;
}

@media screen and (min-width: 600px) {
    .herosectionarry{
      background: url(`+AppCDNa+`/assets/img/webp/BackfullresL-optimized_16-9.webp) bottom / contain no-repeat, #1a1d78!important;
    }
}



.brands img {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%) brightness(90%) sepia(700%) hue-rotate(-180deg) saturate(300%) contrast(0.8); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(100%) brightness(90%) sepia(700%) hue-rotate(-180deg) saturate(300%) contrast(0.8); /* Microsoft Edge and Firefox 35+ */
  opacity: 75%;
}

/* Disable grayscale on hover */
.brands img:hover {
  -webkit-filter: grayscale(0);
  filter: none;
  opacity: 100%;
}

body{
    background-color: #ffffff!important;
    font-family: Rubik, sans-serif;
    text-align: left;
    direction: ltr!important;
    width: 100%;
  }

.stars-1 {
    margin-left: 0px;
    margin-right: 8px;
}  
`


const handleClick = (e) => {
    document.getElementById('navbarNav').classList.remove('show');
  }

const En = () => {


  return (

<div className='En'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppNameEn}: Advanced but Easy cloud solutions</title>


        <meta name="description" content="Llama Link - advanced cloud solutions. Website and App hosting, virtual private servers, personalized email. Easy management through an advanced platform and support from professionals around the clock." />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+": Advanced but Easy cloud solutions"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="Llama Link - advanced cloud solutions. Website and App hosting, virtual private servers, personalized email. Easy management through an advanced platform and support from professionals around the clock." /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="Llama Link - advanced cloud solutions. Website and App hosting, virtual private servers, personalized email. Easy management through an advanced platform and support from professionals around the clock." />
        <meta name="twitter:image" content={SiteImager} />

        <script src="/assets/js/homeNav.js"></script>
        <link href="/assets/css/HomeStyle.css" rel="stylesheet"/>
        <style>{BackStyle}</style>
</Helmet>


  <main className="page lanidng-page">
    <section className="portfolio-block block-intro herosectionarry" style={{paddingTop: 107, paddingBottom: 0, width: '100%', backgroundPositionX: 'center', backgroundPositionY: 'top', marginTop: '-12px', position: 'relative', zIndex: 1}}>
      <div style={{margin: '0 auto', bottom: 0}}>
        <div className="container" style={{paddingLeft: 10, paddingRight: 10, position: 'relative'}}>
          <div className="row" style={{marginLeft: 0, marginRight: 0}}>
            <div className="col-xl-7" style={{paddingRight: 0, paddingLeft: 0, marginTop: 40, marginRight: 0}}><img alt="" className="invisibleimage lazyload" data-src={AppCDNa+'assets/img/webp/benefit%20.webp'} style={{width: '100%', marginTop: 10}} /></div>
            <div className="col-xl-5">
              <div className="_1-75em-bottom-margin">
                <div className="hero-stars-lockup-1">
                  <div className="stars-1">
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                  </div><div className="light-dimmed-text">5/5 • <a style={{color: '#fff'}} href="https://apbs.link/llamalink-google" target="_blank" rel="noopener noreferrer" className="white-text">Google Reviews<span className="hidden-on-mobile"> →</span></a></div></div>
              </div>
              <h1 className="bigusstext" style={{fontFamily: 'Heebo, sans-serif', textAlign: 'left', fontSize: 53, color: '#fdfdfd', paddingBottom: 0, marginBottom: 30, paddingTop: 0, borderColor: 'rgba(34,34,34,0)', lineHeight: '71.8px'}}><strong>A Cloud platform your business really needs</strong><span style={{color: '#34e8ac'}}><strong>.</strong></span></h1>
              <p className="paragherotext" style={{color: '#fdfdfd', textAlign: 'left', fontFamily: 'Heebo, sans-serif', fontSize: 24}}>boost your business/project with a website in one click,<br />Business Emails, virtual private servers<br />All through friendly and advanced management</p>
              <div className="row pb-5" style={{textAlign: 'left'}}>
                <div className="col-xl-11 offset-xl-0" style={{paddingRight: 0, paddingLeft: 0, textAlign: 'left'}}>
                  <a href="signup"><button className="btn btn-primary btnhm" data-bss-hover-animate="pulse" type="button" style={{fontSize: 30}}><strong>signup now</strong></button></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="photo-gallery" />
    <div className="brands" style={{background: '#f7f8ff'}}> 
        <img alt="" data-src={AppCDNa+'assets/img/webp/horizontal-logo-monochromatic-white-730x188.gif'} className="lazyload" style={{width: 140}} />
        <img alt="" data-src={AppCDNa+'assets/img/webp/hp-enterprise.webp'} className="lazyload" style={{width: 180}} />
        <img alt="" data-src={AppCDNa+'assets/img/webp/cloudflare.webp'} className="lazyload" style={{width: 180}} />
        <img alt="" data-src={AppCDNa+'assets/img/nginx.png'} className="lazyload" style={{width: 180}} />
        <img alt="" data-src={AppCDNa+'assets/img/webp/wordpress1.webp'} className="lazyload" style={{width: 180}} />
      </div>
    <section className="portfolio-block call-to-action" id="cloud" style={{width: '100%', padding: 0, paddingTop: 50, paddingBottom: 40, borderWidth: '0px!important'}}>
      <div className="container">
        <h1 className="header-but-categ header-cat-mob block_services-title">Professional cloud solutions for businesses, companies and startups</h1>
        <div className="row">
          <div className="col-xl-12" style={{paddingRight: 8, paddingLeft: 8, paddingTop: 58}}>
            <div className="row" style={{marginRight: 0, marginLeft: 0}}>
              <div className="col-md-4 col-xl-3">
                <NavLink to="" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={Siteurler+'/cdn/icon/domain.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>FAST Web Hosting</strong></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4 col-xl-3">
                <NavLink to="" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={Siteurler+'/cdn/icon/server.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>Private Servers</strong><br /></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4 col-xl-3">
                <NavLink to="" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={Siteurler+'/cdn/icon/email-sign.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>Bussiness Email</strong><br /></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4 col-xl-3">
                <NavLink to="" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={Siteurler+'/cdn/icon/messaging-.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>SMS Messaging</strong></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="portfolio-block call-to-action" style={{width: '100%', paddingBottom: 0, borderWidth: 0, marginTop: 0, paddingTop: 0}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-6" data-aos="zoom-in-up" data-aos-duration={800} data-aos-once="true" style={{paddingTop: 116}}>
            <h1 className="header-but-categ" style={{textAlign: 'left', color: '#19216c', fontFamily: 'Heebo, sans-serif', fontSize: 55}}><strong>Easy to use Platform</strong>.</h1>
            <p style={{textAlign: 'left', color: 'rgb(62,62,62)', fontSize: 25, fontFamily: 'Heebo, sans-serif'}}>Use our cloud services with a friendly management panel that gives you important information, easy configuration capabilities and much more.
            </p></div>
          <div className="col-xl-5" style={{paddingRight: 0, paddingTop: 30, paddingLeft: 33}}><img alt="" data-src={AppCDNa+'assets/img/webp/yosi_im_mahshev_rdy.webp'} className="lazyload" style={{width: '100%'}} /></div>
        </div>
      </div>
    </section>
    {/* <section class="portfolio-block call-to-action" style="width: 100%;padding-bottom: 0px;border-width: 0px;margin-top: 0px;padding-top: 0px;">
      <div class="container" style="margin-top: 30px;margin-bottom: 30px;">
          <div class="row">
              <div class="col-xl-5" style="padding-right: 0px;padding-top: 30px;padding-left: 33px;"><img alt="" src="<?php echo $cdnurler;?>assets/img/webp/wp-plugins-nsz2.webp" style="width: 100%;"></div>
              <div class="col-xl-6" data-aos="zoom-in-up" data-aos-duration="800" data-aos-once="true" style="padding-top: 116px;">
                  <h1 class="header-but-categ" style="text-align: right;color: #19216c;font-family: Heebo, sans-serif;font-size: 55px;"><strong>מאות תוספים</strong>.</h1>
                  <p style="text-align: right;color: rgb(62,62,62);font-size: 25px;font-family: Heebo, sans-serif;">מאות תוספים שימושיים שיאפשרו לכם לבנות ולנהל אתר בקלות ובראש שקט.
              </div>
          </div>
      </div>
  </section> */}
    <section data-aos="fade-up" data-aos-duration={800} data-aos-delay={250} data-aos-once="true" className="portfolio-block call-to-action" style={{width: '100%', padding: 0, paddingTop: 100, paddingBottom: 120, marginTop: '-1px', borderWidth: '0px!important', background: `url("${AppCDNa}/assets/img/webp/features-section.webp")`}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-12" style={{paddingRight: 8, paddingLeft: 8}}>
            <h1 className="header-but-categ" style={{textAlign: 'left', color: 'rgb(42,42,42)', fontFamily: 'Heebo, sans-serif', fontSize: 55, borderColor: 'rgb(255,255,255)', marginTop: 0, paddingBottom: 20}}><strong>with such standards&nbsp;&nbsp;</strong><br />Your business will stand.<br /></h1>
            <div className="row" style={{marginRight: 0, marginLeft: 0}}>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={100} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-ios-bolt" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>DDOS protection</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={150} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-speedometer" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>Stable and fast infrastructure</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={200} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-android-cloud-done" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>Advanced Security Scans</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={300} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-upload" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>Daily website backup</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-delay={250} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-ios-pulse-strong" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}><strong>High system availability, 99%</strong><br /></h3><a href="https://status.llamalink.net" style={{color: 'rgb(90,63,255)'}} target="_blank" rel="noopener noreferrer">Watch our system status&nbsp;<span style={{textDecoration: 'underline'}}>here</span><br /></a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={350} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-ios-heart" style={{background: '#5583ff', paddingTop: 5}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>multichannel professional support</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <section data-bss-parallax-bg="true" className="border-top portfolio-block website gradient" style={{marginTop: '-1px', background: '#ffffff'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-7">
          <h1 style={{textAlign: 'right', color: 'rgb(33,33,33)', fontFamily: '', fontSize: 35}}><strong>Still here? Get 15 days free</strong><br /></h1>
        </div>
        <div className="col-xl-4" style={{textAlign: 'left'}}><a href="signup"><button className="btn btn-primary bigbtn" data-bss-hover-animate="pulse" type="button" style={{background: 'linear-gradient(89deg,#4885ff,#5b54ff)', borderRadius: 15, fontSize: 20, textShadow: '0px 0px 8px rgba(0,0,0,0.25)', marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', marginTop: 8, textAlign: 'center'}}><strong>Try us now</strong></button></a></div>
      </div>
    </div>
  </section>

</div>


  )
}

export default En