import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa } from '../constants/global'

const Soon = () => {

  return (
<div className='Soon'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - בקרוב</title>
        <meta name="description" content="" />
</Helmet>
{/* content goes here */}
<main data-aos="fade" data-aos-once="true" className="page lanidng-page">
  <section className="portfolio-block block-intro backhdbutpc" style={{marginTop: '-13px', paddingTop: 100, paddingBottom: 50, width: '100%', backgroundPositionX: 'right', backgroundPositionY: 'top', background: '#ffffff'}}>
    <div className="container" style={{paddingLeft: 10, paddingRight: 10}}>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col-xl-5" style={{paddingRight: 0, paddingLeft: 0, marginTop: 40, marginBottom: 40, marginRight: 0}}><img src="https://llamalink.net/cdn/website-coming-soon.png" style={{width: '100%'}} /></div>
        <div className="col-xl-7" style={{paddingRight: 40}}>
          <h1 className="bigusstext" style={{fontFamily: 'Heebo, sans-serif', fontSize: 68, color: 'rgb(20,23,79)', textShadow: '6px 6px 1px rgba(0,0,0,0.24)', textAlign: 'right', paddingBottom: 0, marginBottom: 30, paddingTop: 39, borderColor: 'rgba(34,34,34,0)', lineHeight: '91.8px', marginTop: 0}}><strong>בקרוב</strong><span style={{color: '#5a3fff', fontWeight: 500, fontSize: '67px!important'}}>.</span></h1>
          <p style={{color: 'rgb(30,30,30)', textAlign: 'right'}}>אנחנו עדיין עובדים על זה, תחזרו בקרוב<br />יהיה שווה :)</p>
          <div className="row">
            <div className="col-xl-10 offset-xl-0" style={{paddingRight: 0, paddingLeft: 0, textAlign: 'right'}}><a href="/"><button className="btn btn-primary" type="button" style={{background: 'rgb(75,83,228)', borderRadius: 50, fontSize: 26, marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8}}><strong>לדף הבית</strong><br /></button></a></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="photo-gallery" />
  <div className="brands" />
</main>


</div>


  )
}

export default Soon