const AppName = 'למה לינק ענן'
const AppNameEn = 'Llama Link Cloud'
const AppLogo = 'https://cloud.llamalink.net/cdn/webicon.png'
const AppCDNa = 'https://cdn-c.llamalink.net/'
const LinkColorer = '#5367ff'
const Siteurler = 'https://cloud.llamalink.net/'
const Suppurler = 'https://support.llamalink.net/'
const Keywards = `אירוח אתרים, בניית אתרים, שרת וירטואלי, דואר עסקי Llama Link, למה לינק, אירוח אתר וורדפרס`
const SiteTwitter = ""
const SiteImager = "/cdn/city-domains-copyrighted.png"

export { AppName ,AppLogo ,AppCDNa,LinkColorer, Siteurler,Suppurler,Keywards,SiteTwitter,SiteImager,AppNameEn}