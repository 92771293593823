import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName,Keywards,Siteurler,SiteTwitter,SiteImager} from '../constants/global'
import LegalMenu from './components/legal-menu';

const LegalAskForInfo = () => {

  return (
<div className='LegalInfo'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - בקשת מידע</title>

        <meta name="description" content="בקשת מחיקת מידע או מסירת מידע השמור במערכת" />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- בקשת מידע"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="בקשת מחיקת מידע או מסירת מידע השמור במערכת" /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="בקשת מחיקת מידע או מסירת מידע השמור במערכת" />
        <meta name="twitter:image" content={SiteImager} />

</Helmet>
{/* content goes here */}


<main className="page lanidng-page">



<div>
  <section className="portfolio-block block-intro" style={{marginTop: '-4px', paddingTop: 57, paddingBottom: 57, width: '100%', paddingLeft: 0}}><img alt="" src="/assets/img/searching-data.svg" style={{width: 365, marginBottom: 15}} />
    <div className="container" style={{paddingLeft: 10, paddingRight: 10}}>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col">
          <h1 style={{color: 'rgb(40,40,40)', fontSize: 45}}><strong>בקשת מידע</strong></h1>
        </div>
      </div>
    </div>
  </section>
  <section className="photo-gallery" />
  <section className="portfolio-block call-to-action border-bottom" style={{width: '100%', paddingTop: 40, paddingBottom: 40, marginTop: '-9px'}}>
    <div className="container" style={{marginBottom: 0}}>
      <section className="features-boxed" style={{background: 'rgba(238,244,247,0)'}}>
        <div className="container">
          <div className="intro" />
          <div className="row justify-content-center features" style={{paddingBottom: 0, paddingTop: 0, background: 'rgba(255,255,255,0)'}}>
            <LegalMenu/>
            <div className="col-xl-10" style={{color: '#000000!important'}}>
              <hr />
              <h1>קודם כל...<br /></h1>
              <p>חשוב לנו לציין שאכפת לנו מהפרטיות שלכם, אנחנו לא אוספים או שומרים אף מידע מלבד פרטים שסיפקתם לנו בהרשמה לאתר או בשימוש במוצרים שלנו.<br />במידה ובכל זאת אתם מעוניינים לקבל דו"ח הכולל את כל המידע שיש לנו עליכם תוכלו לבצע יצוא מלא כאן בתוספת עמלה של 40 שקלים על הארגון של המידע מכלל הפלטפורמות.<br /></p>
              <div className="row">
                <div className="col-xl-12" style={{paddingTop: 50, paddingBottom: 50}}>
                  <form id="contact" style={{textAlign: 'right', borderRadius: 5, background: '#ffffff'}} action="/legal?data=askforinfo#contact" method="post">                         
                    <div className="form-group"><label htmlFor="subject">מה כתובת הדוא"ל שלכם?</label><input className="form-control form-fildstyle" type="email" name="email" /></div>
                    <div className="form-group"> <div className="g-recaptcha" data-sitekey="<?php echo SITE_KEY; ?>" /></div>
                    <div className="form-group">
                      <div className="form-row">
                        <div className="col-md-6 col-xl-12 button"><button className="btn btn-primary" data-bss-hover-animate="pulse" type="submit" name="submit" id="contact-submit" style={{float: 'right', background: '#5a74ff', borderWidth: 0, borderRadius: 10, fontSize: 20, marginRight: 0, marginLeft: 0, height: 41, width: '100%'}}>הגשת בקשה</button></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</div>




</main>

</div>


  )
}

export default LegalAskForInfo