import React from 'react'
import './styles/NotFound.css'
import { AppNameEn } from '../constants/global'
import { Helmet } from 'react-helmet';


const backStyle = `
body{
    background-color: #ffffff!important;
    font-family: Rubik, sans-serif;
    text-align: left;
    direction: ltr!important;
    width: 100%;
  }
`

const NotFoundEn = () => {
  return (
    <div> 
<Helmet>
        <title>{AppNameEn} - 404 NOT FOUND</title>
        <meta name="description" content="" />
        <style>{backStyle}</style>
</Helmet>     
     

<div id="wrapper">
  <div id="content-wrapper" className="d-flex flex-column" style={{background: `url(/cdn/desert-llama-sized.png`, backgroundSize: 'cover',backgroundPosition: 'center', height: '100vh'}}>
    <div id="content" style={{marginTop: 140}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-2">
          </div>
          <div className="col-xl-8" style={{textAlign: 'center'}}>
            <div className="error mx-auto" data-text={404}>
              <p className="m-0">404</p>
            </div>
            <p className="mb-5 lead" style={{color: '#000000', fontSize: 31}}>The Page you were looking for is not here</p>
            <p className=" mb-0" style={{color: '#000000', textAlign: 'center', fontSize: 19}}>But if you were looking for mountains, then there are many here..</p><a href="/en" style={{color: '#0932ff'}}>← back home</a>
          </div>
          <div className="col-xl-2">
            {/* <img src="https://llamalink.net/cdn/desert-llama.png" style="width: 100%;border-radius: 10px;" /> */}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="text-center mt-5" style={{color: 'rgb(68,199,255)'}} />
      </div>
    </div>
  </div>
</div>



    </div>
    
  )
}

export default NotFoundEn