import React, { useState } from 'react';
import { AppName,AppNameEn ,AppLogo } from '../../constants/global'
import { NavLink } from 'react-router-dom'


const Menuen = () => {

  const handleClick = (e) => {
    document.getElementById('navbarNav').classList.remove('show');
  }


        // chatwoot en
        (function(d,t) {
            var BASE_URL="https://chatwoote.apbs.link";
            var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
            g.src=BASE_URL+"/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g,s);
            g.onload=function(){
            window.chatwootSDK.run({
                websiteToken: 'GgYTg4Au1nhS5JTcnkf9kakY',
                baseUrl: BASE_URL,
                customCSS: '.branding--link{display: none;}'
            })
            }

            window.chatwootSettings = {
                hideMessageBubble: false,
                position: 'right', // This can be left or right
                locale: 'en', // Language to be set
                type: 'standard', // [standard, expanded_bubble]
                darkMode: "light", // [light, auto]
                launcherTitle: "Chat with us",
                //showPopoutButton: true,
            };
        })(document,"script");

        window.addEventListener("chatwoot:ready", function () {
        // Use window.$chatwoot here
        // ...

            //window.$chatwoot.toggle("open"); // To open widget
        });
 

        //userway accessability
        (function(d){
            var s = d.createElement("script");
            s.setAttribute("data-position", 5);
            s.setAttribute("data-account", "6BvIJKS8sD");
            s.setAttribute("src", "https://cdn.userway.org/widget.js");
            (d.body || d.head).appendChild(s);
          })(document)


  return (
<nav className="navbar navbar-expand-lg sticky-top portfolio-navbar navbarer" style={{boxShadow: '0 4px 10px rgba(0,0,0,0)', width: '100%', paddingTop: '10.2px', paddingBottom: '10.2px'}}>
  <div className="container-fluid">
    <a className="navbar-brand logo logoer" href="/en">
      <img src={AppLogo} style={{animationDuration: '1s', width: 61}} alt="LlamaLink" />
      &nbsp; {AppNameEn}</a>
    <button data-toggle="collapse" data-target="#navbarNav" className="navbar-toggler mobiler">
      <i className="fa fa-bars" />
      <span className="sr-only" style={{color: 'rgb(75,83,228)'}}>Open Menu</span>
    </button>
    <div className={`navbar-collapse collapse`} id="navbarNav">
      <ul className="navbar-nav ml-auto" style={{marginLeft: 'unset!important'}}>
        <li className="nav-item dropdown" style={{paddingLeft: 0, paddingRight: 32}}><a className="nav-link nav-link-color menurcolor" aria-expanded="false" href="/en#cloud" style={{fontSize: 18}}>Cloud Services&nbsp;</a>
          {/* <div className="dropdown-menu animated" style={{fontSize: 18, borderRadius: 7, textAlign: 'left', borderWidth: 0, boxShadow: '0px 0px 6px 0px rgba(33,37,41,0.36)', paddingRight: 21}}>
            <NavLink className="dropdown-item" to="/websites" onClick={handleClick} style={{borderRadius: 9, background: '#e4fff6', margin: 9}}><i className="fas fa-globe" style={{background: '#34e8ac', padding: 5, fontSize: 20, borderRadius: 9}} />&nbsp;Web Hosting</NavLink>
            <NavLink className="dropdown-item" to="/vps" onClick={handleClick} style={{borderRadius: 9, background: '#fff8e4', margin: 9}}><i className="fas fa-server" style={{padding: 5, fontSize: 20, background: '#ffb259', borderRadius: 9}} /> &nbsp;Virtual Servers</NavLink>
            <NavLink className="dropdown-item" to="/domains" onClick={handleClick} style={{borderRadius: 9, background: '#e4e6ff', margin: 9}}><i className="fas fa-comments" style={{padding: 5, fontSize: 18, borderRadius: 9, background: '#8389ff'}} />&nbsp;SMS Messaging</NavLink>
            <NavLink className="dropdown-item" to="/email" onClick={handleClick} style={{borderRadius: 9, background: '#ffe4e4', margin: 9}}><i className="fas fa-envelope" style={{padding: 5, fontSize: 20, borderRadius: 9, background: '#ff556d'}} />&nbsp;Bussiness Email</NavLink>            
          </div> */}
        </li>
        {/* <li className="nav-item" style={{paddingLeft: 0, paddingRight: 32}}><NavLink className="nav-link nav-link-color" data-bss-hover-animate="pulse" to="/pricing" onClick={handleClick} style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>Pricing</NavLink></li>
        <li className="nav-item" style={{paddingLeft: 0, paddingRight: 32}}><NavLink className="nav-link nav-link-color" data-bss-hover-animate="pulse" to="/support" onClick={handleClick} style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>Help Center</NavLink></li>
        <li className="nav-item" style={{paddingLeft: 0, paddingRight: 32}}><NavLink className="nav-link nav-link-color" data-bss-hover-animate="pulse" to="/about" onClick={handleClick} style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>About</NavLink></li> */}
        <li className="nav-item" style={{paddingLeft: 0, paddingRight: 32}}><a className="nav-link nav-link-color" data-bss-hover-animate="pulse" onClick={() => window.$chatwoot.toggle('open')} href="#chat" style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>Talk to us</a></li>
        <li className="nav-item" style={{paddingLeft: 0, paddingRight: 32}}><a className="nav-link nav-link-color" data-bss-hover-animate="pulse" href="about" style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>About</a></li>
        <li className="nav-item dropdown" style={{paddingLeft: 0, paddingRight: 32}}>
            <a className="dropdown-toggle nav-link nav-link-color menurcolor" aria-expanded="false" data-toggle="dropdown" onClick={handleClick} href="#" style={{fontSize: 18}}><img src="/assets/img/usa-f.png" width={30} /> English&nbsp;</a>
          <div className="dropdown-menu animated" style={{fontSize: 18, borderRadius: 7, textAlign: 'center', borderWidth: 0, boxShadow: '0px 0px 6px 0px rgba(33,37,41,0.36)', paddingRight: 21}}>
            <a className="dropdown-item" href="/en" onClick={handleClick} style={{borderRadius: 9, background: '#e4fff1', margin: 9}}><img src="/assets/img/usa-f.png" width={30} />&nbsp;English</a>
            <a className="dropdown-item" href="/" onClick={handleClick} style={{borderRadius: 9, background: '#e4fff1', margin: 9}}><img src="/assets/img/israel-f.png" width={30} />&nbsp;עברית</a>
          </div>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto" style={{marginLeft: 'unset!important', marginRight: '17.9844px', paddingRight: 0}}>
        <li className="nav-item" style={{paddingLeft: 0, paddingRight: 14, fontSize: 16, marginTop: 5}}><a className="btn btn-outline-primary loginer" role="button" href="/app">Login</a></li>
        <li className="nav-item" style={{paddingLeft: 0, paddingRight: 32, marginTop: 5}}><a className="btn btn-primary registerer" role="button" href="/signup" data-bss-hover-animate="pulse" type="button">Signup</a></li>
      </ul>
    </div>
  </div>
</nav>


  )
}

export default Menuen