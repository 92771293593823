import React from 'react'
import { useEffect } from 'react';

import './App.css'
import './main.css'
import './app/styles/menu.css'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'

import RouteSpecificCSS from './RouteSpecificCSS';
import Menu from './app/components/menu'
import Footer from './app/components/Footer';


import { AppName } from './constants/global'

import Home from './app/Home'
import About from './app/About'
import NotFound from './app/NotFound'

import { Helmet } from 'react-helmet';
import Contact from './app/Contact';
import Help from './app/Help';
import LegalPrivacy from './app/LegalPrivacy'
import LegalAccessibility from './app/LegalAccessibility'
import LegalAskForInfo from './app/LegalAskForInfo.js'
import LegalTermsOfService from './app/LegalTermsOfService'
import Vps from './app/Vps'
import Websites from './app/Websites'
import Pricing from './app/Pricing';
import Soon from './app/Soon';
import Signup from './app/Signup';
import En from './app/En';
import Menuen from './app/components/menu-en';
import NotFoundEn from './app/NotFoundEn';



///////////////////////////////////
function LegalRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
      // 👇 Redirects to about page, note the `replace: true`
      navigate('/legal/terms-of-service', { replace: true });
  }, []);
}
///////////////////////////////////

const App = () => {
 
 

  return (
    <>
    <Helmet>
        <title>{AppName}</title>
        <meta name="description" content="" />
    </Helmet>

    <Routes>
          <Route exact path='/' element={<><Menu/> <Home /> <Footer/></>}/>
          <Route exact path='/en' element={<><Menuen/> <En /></>}/>
          <Route exact path="/en/*" element={<><Menuen/> <NotFoundEn /></>} status={404}/>
          <Route exact path='/signup' element={<><Menu/> <Signup /> <Footer/></>}/>
          <Route exact path='/help' element={<><Menu/> <Contact /> <Footer/></>}/>
          <Route exact path='/contact' element={<><Menu/> <Contact /> <Footer/></>}/>
          <Route exact path='/pricing' element={<><Menu/> <Pricing /> <Footer/></>}/>

          <Route exact path='/sms' element={<><Menu/> <Soon /> <Footer/></>}/>
          <Route exact path='/domains' element={<><Menu/> <Soon /> <Footer/></>}/>
          <Route exact path='/email' element={<><Menu/> <Soon /> <Footer/></>}/>

          <Route exact path='/about' element={<><Menu/> <About /> <Footer/></>}/>
          <Route exact path='/vps' element={<><Menu/> <Vps /> <Footer/></>}/>
          <Route exact path='/websites' element={<><Menu/> <Websites /> <Footer/></>}/>
          <Route exact path='/legal' element={<><Menu/> <LegalRedirect /> <Footer/></>}/>
          <Route exact path='/legal/privacy-policy' element={<><Menu/> <LegalPrivacy /> <Footer/></>}/>
          <Route exact path='/legal/accessibility' element={<><Menu/> <LegalAccessibility /> <Footer/></>}/>
          <Route exact path='/legal/terms-of-service' element={<><Menu/> <LegalTermsOfService /> <Footer/></>}/>
          <Route exact path='/legal/askforinfo' element={<><Menu/> <LegalAskForInfo /> <Footer/></>}/>
          <Route exact path="*" element={<><Menu/> <NotFound /> <Footer/></>} status={404}/>
    </Routes>
    </>
  );
}

export default App;
