import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName,Keywards,Siteurler,SiteTwitter,SiteImager } from '../constants/global'

const Signup = () => {

  return (
<div className='Signup'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - הרשמה</title>
        <meta name="description" content="הצטרפות ללמה לינק. הצטרפו עכשיו ותהנו מ15 יום נסיון חינם של אירוח אתרים. ניהול קל ומהיר, צוות תמיכה מיומן, עשרות תוספים שימושיים" />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- הרשמה"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="הצטרפות ללמה לינק. הצטרפו עכשיו ותהנו מ15 יום נסיון חינם של אירוח אתרים. ניהול קל ומהיר, צוות תמיכה מיומן, עשרות תוספים שימושיים" /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="הצטרפות ללמה לינק. הצטרפו עכשיו ותהנו מ15 יום נסיון חינם של אירוח אתרים. ניהול קל ומהיר, צוות תמיכה מיומן, עשרות תוספים שימושיים" />
        <meta name="twitter:image" content={SiteImager} />
</Helmet>
{/* content goes here */}

<main className="page lanidng-page">
  <section className="photo-gallery" />
  <section className="portfolio-block call-to-action border-bottom" style={{width: '100%', paddingTop: 80, paddingBottom: 40, marginTop: '-3px', background: 'linear-gradient(to bottom, #fff, #d2dcff)'}}>
    <div className="container" style={{paddingLeft: 10, paddingRight: 10, marginBottom: 60}}>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col-xl-12" style={{paddingTop: 50, paddingBottom: 50}}>
          <form style={{textAlign: 'right', borderRadius: 10, background: '#ffffff'}}>
            <div className="form-group">
              <div className="form-row">
                <div className="col">
                  <h1 style={{fontSize: 21, textAlign: 'center'}}>אופס, אנחנו על זה.<br />הרשמה עצמאית תתאפשר בקרוב.</h1>
                  <p> <a href="/contact">בינתיים תוכלו ליצור איתנו קשר בכדי להצטרף</a> </p>
                </div>
              </div>
            </div><img src="assets/img/sharatimERROR.png" style={{width: '100%'}} />
          </form>
        </div>
      </div>
    </div>
  </section>
</main>

</div>


  )
}

export default Signup