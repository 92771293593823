import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa,Keywards,Siteurler,SiteTwitter,SiteImager } from '../constants/global'
import './styles/contact.css'

const Contact = () => {

  return (
<div className='Contact'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - צרו קשר</title>
        
        <meta name="description" content="מנסים ליצור קשר עם למה לינק? כאן תוכלו ליצור קשר בדרך הנוחה לכם. אנחנו זמינים בוואצאפ, בצאט ישיר עם נציג ובמייל." />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- צרו קשר"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="מנסים ליצור קשר עם למה לינק? כאן תוכלו ליצור קשר בדרך הנוחה לכם. אנחנו זמינים בוואצאפ, בצאט ישיר עם נציג ובמייל." /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="מנסים ליצור קשר עם למה לינק? כאן תוכלו ליצור קשר בדרך הנוחה לכם. אנחנו זמינים בוואצאפ, בצאט ישיר עם נציג ובמייל." />
        <meta name="twitter:image" content={SiteImager} />

</Helmet>

<div>
  <main data-aos-once="true" className="page lanidng-page">
    <section className="photo-gallery" />
    <section className="portfolio-block call-to-action" style={{width: '100%', paddingTop: 80, paddingBottom: 160, marginTop: '-8px', borderWidth: 0, borderStyle: 'none', background: '#F4F5FA'}}>
      <div className="container" style={{paddingLeft: 10, paddingRight: 10}}>
        <div className="row" style={{marginLeft: 0, marginRight: 0}}>
          <div className="col" style={{marginBottom: 30}}>
            <h1 style={{color: 'rgb(0,0,0)', fontSize: 45, textAlign: 'center', marginBottom: 20, fontFamily: 'Heebo, sans-serif'}}><strong>צרו איתנו קשר</strong><span style={{color: '#2a60ff'}}><strong>.</strong></span></h1>
            <p style={{textAlign: 'center', color: 'rgb(32,28,72)', fontSize: 25}}>רוצים לפנות אלינו? אנחנו זמינים בשבילכם בכל נושא <br />בימים א' עד ה' בין השעות 9:00 ל19:00<br /></p>
          </div>
        </div>
        <div className="row justify-content-center features" style={{paddingBottom: 0, paddingTop: 0, background: 'rgba(255,255,255,0)'}}>
          <div className="col-sm-6 col-md-5 col-lg-4 item" data-aos="fade-up" data-aos-duration={700} data-aos-delay={50} data-aos-once="true">
            <div className="box" style={{borderRadius: 11, height: 380, paddingTop: 25, justifyContent: 'center'}}>
              <p className="description" style={{fontSize: 30, textAlign: 'center', color: 'rgb(32,28,72)'}}>כאן<br /></p>
              <div className="contact-icons"><i className="fas fa-comments" style={{fontSize: 113, color: 'rgb(60,91,255)'}} /></div>
              <h3 className="name" style={{textAlign: 'center', marginRight: 0, fontSize: 22, fontWeight: 'normal', color: 'rgb(32,28,72)'}}>בצ'אט שלנו<br /><a onClick={() => window.$chatwoot.toggle('open')} href="contact#chat" style={{color: '#0ea0ff'}}><strong>(לחצו כאן)</strong></a></h3>
            </div>
          </div>
          <div className="col-sm-6 col-md-5 col-lg-4 item" data-aos="fade-up" data-aos-duration={800} data-aos-delay={250} data-aos-once="true">
            <div className="box" style={{borderRadius: 11, height: 380, paddingTop: 25}}>
              <p className="description" style={{fontSize: 30, textAlign: 'center', color: 'rgb(32,28,72)'}}>וכאן<br /></p>
              <div className="contact-icons"><i className="fab fa-whatsapp" style={{fontSize: 113, color: 'rgb(29,203,46)'}} /></div>
              <h3 className="name" style={{textAlign: 'center', marginRight: 0, fontSize: 22, fontWeight: 'normal', color: 'rgb(32,28,72)'}}>בוואצאפ<br /><a href="https://apbs.link/whatsapp" target="_blank" rel="noopener noreferrer"><strong dir="ltr">+972 50 492 8067</strong></a></h3>
            </div>
          </div>
          <div className="col-sm-6 col-md-5 col-lg-4 item" data-aos="fade-up" data-aos-duration={800} data-aos-delay={150} data-aos-once="true">
            <div className="box" style={{borderRadius: 11, height: 380, paddingTop: 25}}>
              <p className="description" style={{fontSize: 30, textAlign: 'center', color: 'rgb(32,28,72)'}}>וגם כאן<br /></p>
              <div className="contact-icons"><i className="fa fa-envelope" style={{fontSize: 113, color: 'rgb(255,72,60)'}} /></div>
              <h3 className="name" style={{textAlign: 'center', marginRight: 0, fontSize: 22, fontWeight: 'normal', color: 'rgb(32,28,72)'}}>במייל<br /><a href="mailto:contact@llamalink.net" target="_blank" rel="noopener noreferrer"><strong>contact@llamalink.net</strong></a><br /></h3>
            </div>
          </div>
        </div>                    
      </div>
    </section>
  </main>
  {/* <section id="allway" className="portfolio-block" style={{marginTop: '-3px', background: '#F4F5FA', borderStyle: 'none'}}>
    <div className="container" style={{paddingLeft: 10, paddingRight: 10, marginBottom: 60}}>
      <h1 style={{textAlign: 'center', color: 'rgb(32,28,72)', fontFamily: 'Heebo, sans-serif', fontSize: 38, marginBottom: 30}}><strong>וגם בטופס כאן.</strong></h1>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col-xl-5" style={{paddingTop: 50, paddingBottom: 50}}><img alt="" src={AppCDNa + 'assets/img/webp/mailbox.webp'} style={{width: '100%'}} /></div>
        <div className="col-xl-7" style={{paddingTop: 50, paddingBottom: 50}}>
          <form style={{textAlign: 'right', borderRadius: 9, background: '#ffffff'}} id="form" method="post" action="/contact#form">                                  
            <div className="form-group"><label htmlFor="name">השם המלא שלכם</label><input className="form-control form-fildstyle" name="name" type="text" required /></div>
            <div className="form-group"><label htmlFor="email">כתובת האימייל שלכם (כדי שנוכל לחזור אליכם)</label><input className="form-control form-fildstyle" name="email" type="email" id="email" required /></div>
            <div className="form-group"><label htmlFor="message">איך נוכל לסייע?</label><textarea className="form-control form-fildstyle" name="message" id="message" required defaultValue={""} /></div>
            <div className="form-group"> <div className="g-recaptcha" data-sitekey="<?php echo SITE_KEY; ?>" /></div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6 col-xl-12 button"><button className="btn btn-primary" data-bss-hover-animate="pulse" type="submit" name="submit" id="contact-submit" style={{float: 'right', background: '#5a74ff', borderWidth: 0, borderRadius: 10, fontSize: 20, marginRight: 0, marginLeft: 0, height: 41, width: '100%'}}>שליחה</button></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section> */}
</div>


</div>


  )
}

export default Contact