import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa,Keywards,Siteurler,SiteTwitter,SiteImager } from '../constants/global'

const Vps = () => {

  return (
<div className='Vps'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - שרתים וירטואלים פרטיים</title>
        
        <meta name="description" content="שרתים וירטואליים פרטיים עם ביצועים שלא ראיתם. מגוון מערכות הפעלה ותשתית אינטרנט מהירה." />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- שרתים וירטואלים פרטיים"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="שרתים וירטואליים פרטיים עם ביצועים שלא ראיתם. מגוון מערכות הפעלה ותשתית אינטרנט מהירה." /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="שרתים וירטואליים פרטיים עם ביצועים שלא ראיתם. מגוון מערכות הפעלה ותשתית אינטרנט מהירה." />
        <meta name="twitter:image" content={SiteImager} />
        
</Helmet>
{/* content goes here */}

<main className="page lanidng-page">
  <section className="portfolio-block block-intro backhdbutpc" style={{marginTop: '-13px', paddingTop: 100, paddingBottom: 50, width: '100%', backgroundPositionX: 'right', backgroundPositionY: 'top', background: '#fff'}}>
    <div className="container" style={{paddingLeft: 10, paddingRight: 10}}>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col-xl-7" style={{paddingRight: 0, paddingLeft: 0, marginTop: 40, marginBottom: 40, marginRight: 0}}>
          <div style={{animationDuration: '14s', padding:'15px', background:'#fff8ee',borderRadius:'12px'}}><video className="mobilervid" width="100%" autoPlay loop muted>
              <source src={AppCDNa+"assets/video/vps-lightsNEWR.mp4"} type="video/webm" />
            </video></div>
        </div>
        <div className="col-xl-5" style={{paddingRight: 40}}>
          <h1 className="bigusstext" style={{fontFamily: 'Heebo, sans-serif', fontSize: 68, color: 'rgb(20,23,79)', textShadow: '6px 6px 1px rgba(0,0,0,0.24)', textAlign: 'right', paddingBottom: 0, marginBottom: 30, paddingTop: 39, borderColor: 'rgba(34,34,34,0)', lineHeight: '91.8px', marginTop: 0}}><strong>שרתים וירטואליים </strong><br /><strong>לכל מטרה</strong><span style={{color: '#5a3fff', fontWeight: 500, fontSize: '67px!important'}}>.</span></h1>
          <p style={{color: 'rgb(30,30,30)', textAlign: 'right'}}>שרת וירטואלי פרטי עם חומרה מותאמת אישית, מגוון מערכות הפעלה, חיבור אינטרנט מהיר וניהול ידידותי דרך הדפדפן.<br /></p>
          <div className="row">
            <div className="col-xl-10 offset-xl-0" style={{paddingRight: 0, paddingLeft: 0, textAlign: 'right'}}><a href="signup"><button className="btn btn-primary" type="button" style={{background: '#ffb259', borderRadius: '15px', fontSize: 26, marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8}}>{/* <i class="fa fa-plus"></i> */}<strong>&nbsp;הקמת שרת עכשיו</strong><br /></button></a></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="photo-gallery" />
  <div className="brands"><img alt="" data-aos="fade-up" data-aos-duration={650} data-aos-delay={250} data-aos-once="true" src="assets/img/proxmox.png" style={{width: 180}} /><img alt="" data-aos="fade-up" data-aos-duration={650} data-aos-delay={250} data-aos-once="true" src="assets/img/vmware.png" style={{width: 180}} /><img alt="" data-aos="fade-up" data-aos-duration={700} data-aos-delay={300} data-aos-once="true" src="assets/img/Linux_logo.png" style={{width: 111}} /><img alt="" data-aos="fade-up" data-aos-duration={750} data-aos-delay={350} data-aos-once="true" src="assets/img/Windows_logo_and_wordmark_-_2012–2021.svg.png" style={{width: 180}} /></div>
  <section data-aos="fade-up" data-aos-duration={850} data-aos-delay={250} data-aos-once="true" className="portfolio-block call-to-action border-bottom" style={{width: '100%', padding: 0, paddingTop: 100, paddingBottom: 189, marginTop: '-1px', borderWidth: '0px!important', background: 'url("assets/img/backvpscreens.png") center no-repeat, url("assets/img/features-section.png") center / cover no-repeat, #fff'}}>
    <div className="container" style={{marginTop: 30, marginBottom: 30}}>
      <div className="row">
        <div className="col-xl-12" style={{paddingRight: 8, paddingLeft: 8}}>
          <h1 style={{textAlign: 'right', color: 'rgb(42,42,42)', fontFamily: 'Heebo, sans-serif', fontSize: 55, borderColor: 'rgb(255,255,255)', marginTop: 0, paddingBottom: 30}}><strong>למה אצלנו?</strong><br /></h1>
          <div className="row" style={{marginRight: 0, marginLeft: 0}}>
            <div className="col-md-4">
              <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-once="true" style={{borderRadius: 10}}>
                <div className="card-header bg-transparent border-0"><i className="icon ion-ios-speedometer" style={{background: '#ffb259'}} /></div>
                <div className="card-body">
                  <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>תשתית אינטרנט סופר מהירה</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-delay={100} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                <div className="card-header bg-transparent border-0"><i className="fas fa-hdd" style={{background: '#ffb259', fontSize: 46, paddingTop: 10, paddingLeft: 0, width: 70, height: 70, borderRadius: 50, color: 'rgb(255,255,255)'}} /></div>
                <div className="card-body">
                  <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>אחסון מהיר מבוסס NVME</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-delay={150} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                <div className="card-header bg-transparent border-0"><i className="icon ion-leaf" style={{background: '#ffb259'}} /></div>
                <div className="card-body">
                  <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>ניהול ידידותי דרך הדפדפן</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-delay={300} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                <div className="card-header bg-transparent border-0"><i className="fab fa-docker" style={{background: '#ffb259', fontSize: 45, paddingTop: 9, paddingLeft: 7, width: 70, height: 70, color: 'rgb(255,255,255)', borderRadius: 50}} /></div>
                <div className="card-body">
                  <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>אירוח Docker מתקדם</h3>
                  <p style={{color: 'rgb(20,23,79)'}}>אפשרות לאחסון קונטיינרים מנוהלים על ידי Portainer, פאנל ניהול קל ומהיר שחוסך זמן.<br /></p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-delay={200} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                <div className="card-header bg-transparent border-0"><i className="icon ion-play" style={{background: '#ffb259', paddingTop: 4, paddingRight: 0, paddingLeft: 7}} /></div>
                <div className="card-body">
                  <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>מגוון מערכות הפעלה מוכנות, תוכלו גם להתקין אחת משלכם</h3>
                  <p style={{color: 'rgb(20,23,79)'}}>*כפוף לתקנון ולתאימות חומרתית.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-delay={250} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                <div className="card-header bg-transparent border-0"><i className="icon ion-ios-pulse-strong" style={{background: '#ffb259'}} /></div>
                <div className="card-body">
                  <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>זמינות שרתים מלאה, 99%<br /></h3><a href="https://apbs.link/status" style={{color: '#ffb259'}} target="_blank"><br />מצב המערכת&nbsp;<span style={{textDecoration: 'underline'}}>כאן</span><br /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 style={{textAlign: 'center', paddingTop: 55}}>אתם עדיין פה?&nbsp; &nbsp;<a href="signup"><button className="btn btn-primary" type="button" style={{background: '#ffb259', borderRadius: '15px', fontSize: 25, marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8}}><strong>בנו שרת עכשיו</strong><br /></button></a></h1>
  </section>
</main>


</div>


  )
}

export default Vps