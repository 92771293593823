import React, { useState } from 'react';
import { AppName ,AppLogo, AppCDNa } from '../../constants/global'
import { NavLink } from 'react-router-dom'


const Menu = () => {

  const handleClick = (e) => {
    document.getElementById('navbarNav').classList.remove('show');
  }


        // chatwoot he
        (function(d,t) {
          var BASE_URL="https://chatwoote.apbs.link";
          var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
          g.src=BASE_URL+"/packs/js/sdk.js";
          g.defer = true;
          g.async = true;
          s.parentNode.insertBefore(g,s);
          g.onload=function(){
          window.chatwootSDK.run({
              websiteToken: 'A6xHWRTuJCoTwygMjeiyErHk',
              baseUrl: BASE_URL,
              customCSS: '.branding--link{display: none;}'
          })
          }

          window.chatwootSettings = {
              hideMessageBubble: false,
              position: 'left', // This can be left or right
              locale: 'he', // Language to be set
              type: 'standard', // [standard, expanded_bubble]
              darkMode: "light", // [light, auto]
              launcherTitle: "Chat with us",
              //showPopoutButton: true,
          };
      })(document,"script");

      
        //userway accessability
        (function(d){
          var s = d.createElement("script");
          s.setAttribute("data-position", 3);
          s.setAttribute("data-account", "6BvIJKS8sD");
          s.setAttribute("src", "https://cdn.userway.org/widget.js");
          (d.body || d.head).appendChild(s);
        })(document)




  return (
<nav className="navbar navbar-expand-lg sticky-top portfolio-navbar navbarer" style={{boxShadow: '0 4px 10px rgba(0,0,0,0)', width: '100%', paddingTop: '10.2px', paddingBottom: '10.2px'}}>
  <div className="container-fluid">
    <a className="navbar-brand logo logoer" href="/">
      <img src={AppLogo} style={{animationDuration: '1s', width: 61}} alt="LlamaLink" />
      &nbsp; {AppName}</a>
    <button data-toggle="collapse" data-target="#navbarNav" className="navbar-toggler mobiler">
      <i className="fa fa-bars" />
      <span className="sr-only" style={{color: 'rgb(75,83,228)'}}>פתיחת תפריט</span>
    </button>
    <div className={`navbar-collapse collapse`} id="navbarNav">
      <ul className="navbar-nav mr-auto" style={{marginLeft: 'unset!important'}}>
        <li className="nav-item dropdown" style={{paddingRight: 0, paddingLeft: 32}}><NavLink className="dropdown-toggle nav-link nav-link-cloud nav-link-color menurcolor" aria-expanded="false" data-toggle="dropdown" to="#cloud" style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>פתרונות&nbsp;</NavLink>
          <div className="dropdown-menu animated" style={{fontSize: 18, borderRadius: 7, textAlign: 'right', borderWidth: 0, boxShadow: '0px 0px 6px 0px rgba(33,37,41,0.36)', paddingLeft: 21}}>
            <NavLink className="dropdown-item" to="/websites" onClick={handleClick} style={{borderRadius: 9, background: 'rgb(246 255 252)', margin: 9}}><img data-src={AppCDNa+'/assets/img/icon/domain.svg'} className="lazyload"style={{background: '#fff', padding: 2, width: 35, borderRadius: 9}} />&nbsp;אירוח אתרים</NavLink>
            <NavLink className="dropdown-item" to="/vps" onClick={handleClick} style={{borderRadius: 9, background: 'rgb(246 255 252)', margin: 9}}><img data-src={AppCDNa+'/assets/img/icon/server.svg'} className="lazyload"style={{background: '#fff', padding: 2, width: 35, borderRadius: 9}} /> &nbsp;שרתים וירטואליים</NavLink>
            <NavLink className="dropdown-item" to="/domains" onClick={handleClick} style={{borderRadius: 9, background: 'rgb(246 255 252)', margin: 9}}><img data-src={AppCDNa+'/assets/img/icon/messaging-.svg'} className="lazyload"style={{background: '#fff', padding: 2, width: 35, borderRadius: 9}} />&nbsp;SMS עסקי</NavLink>
            <NavLink className="dropdown-item" to="/email" onClick={handleClick} style={{borderRadius: 9, background: 'rgb(246 255 252)', margin: 9}}><img data-src={AppCDNa+'/assets/img/icon/email-sign.svg'} className="lazyload"style={{background: '#fff', padding: 2, width: 35, borderRadius: 9}} />&nbsp;אימייל עסקי</NavLink>
          </div>
        </li>
        <li className="nav-item" style={{paddingRight: 0, paddingLeft: 32}}><NavLink className="nav-link nav-link-color" data-bss-hover-animate="pulse" to="/pricing" onClick={handleClick} style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>מחירון</NavLink></li>
        <li className="nav-item" style={{paddingRight: 0, paddingLeft: 32}}><NavLink className="nav-link nav-link-color" data-bss-hover-animate="pulse" to="/about" onClick={handleClick} style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>אודות</NavLink></li>
        <li className="nav-item" style={{paddingRight: 0, paddingLeft: 32}}><NavLink className="nav-link nav-link-color" data-bss-hover-animate="pulse" to="/contact" onClick={handleClick} style={{fontFamily: 'Heebo, sans-serif', fontSize: 18}}>צרו קשר</NavLink></li>
        <li className="nav-item dropdown" style={{paddingRight: 0, paddingLeft: 32}}><a className="dropdown-toggle nav-link nav-link-color menurcolor" aria-expanded="false" data-toggle="dropdown" href="" style={{fontSize: 18}}><img src="/assets/img/israel-f.png" width={30} /> עברית&nbsp;</a>
          <div className="dropdown-menu animated" style={{fontSize: 18, borderRadius: 7, textAlign: 'center', borderWidth: 0, boxShadow: '0px 0px 6px 0px rgba(33,37,41,0.36)', paddingLeft: 21}}>
            <a className="dropdown-item" href="/en" onClick={handleClick} style={{borderRadius: 9, background: '#e4fff1', margin: 9}}><img src="/assets/img/usa-f.png" width={30} />&nbsp;English</a>
            <a className="dropdown-item" href="/" onClick={handleClick} style={{borderRadius: 9, background: '#e4fff1', margin: 9}}><img src="/assets/img/israel-f.png" width={30} />&nbsp;עברית</a>
          </div>
        </li>
      </ul>

      <ul className="navbar-nav mr-auto" style={{marginLeft: 'unset!important', marginRight: '17.9844px', paddingRight: 0}}>
        <li className="nav-item" style={{paddingRight: 0, paddingLeft: 14, fontSize: 16, marginTop: 5}}><a className="btn btn-outline-primary loginer" role="button" href="/app">כניסה</a></li>
        <li className="nav-item" style={{paddingRight: 0, paddingLeft: 32, marginTop: 5}}><a className="btn btn-primary registerer" role="button" href="/signup" data-bss-hover-animate="pulse" type="button">התחילו עכשיו</a></li>
      </ul>
    </div>
  </div>
</nav>

  )
}

export default Menu