import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa,Keywards,Siteurler,SiteTwitter,SiteImager} from '../constants/global'
import LegalMenu from './components/legal-menu';

const LegalAccessibility = () => {

  return (
<div className='PageName'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - הצהרת נגישות</title>

        <meta name="description" content="הצהרת נגישות המוצרים והאתרים של למה לינק" />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- הצהרת נגישות"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="הצהרת נגישות המוצרים והאתרים של למה לינק" /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="הצהרת נגישות המוצרים והאתרים של למה לינק" />
        <meta name="twitter:image" content={SiteImager} />

</Helmet>
{/* content goes here */}


<main className="page lanidng-page">
<div>
  <section className="portfolio-block block-intro" style={{marginTop: '-4px', paddingTop: 57, paddingBottom: 57, width: '100%', paddingLeft: 0}}><img alt="" src="/cdn/wheel_left_bl.svg" style={{width: 160, marginBottom: 15}} />
    <div className="container" style={{paddingLeft: 10, paddingRight: 10}}>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col">
          <h1 style={{color: 'rgb(40,40,40)', fontSize: 45}}><strong>נגישות האתר</strong></h1>
        </div>
      </div>
    </div>
  </section>
  <section className="photo-gallery" />
  <section className="portfolio-block call-to-action border-bottom" style={{width: '100%', paddingTop: 40, paddingBottom: 40, marginTop: '-9px'}}>
    <div className="container" style={{marginBottom: 0}}>
      <section className="features-boxed" style={{background: 'rgba(238,244,247,0)'}}>
        <div className="container">
          <div className="intro" />
          <div className="row justify-content-center features" style={{paddingBottom: 0, paddingTop: 0, background: 'rgba(255,255,255,0)'}}>
            <LegalMenu/>
              <div className="col-xl-10" style={{color: '#000000!important'}}>
                <hr /><h1>הצהרת הנגישות של למה לינק</h1>
                <br /><br />
                עדכןן אחרון: יוני 2022.
                <br /><br />
                מסמך זה מנוסך בלשון זכר בשל תרגום אוטומטי, אך פונה לגברים ונשים כאחד.
                <br /><br />
                <h2>כללי</h2>
                למה לינק שואפת להבטיח שהשירותים שלה יהיו נגישים לאנשים עם מוגבלויות. למה לינק השקיעה כמות משמעותית של משאבים כדי להבטיח שהאתר שלה יהיה קל יותר לשימוש ונגיש יותר עבור אנשים עם מוגבלות, מתוך אמונה חזקה שמאמצי הנגשת אתרים מסייעים לכל המשתמשים ושלכל אדם יש את הזכות לחיות בכבוד, שוויון, נוחות ועצמאות.
                <h2>נגישות ב-{Siteurler}</h2>
                {Siteurler} מפעילים את <a rel="noopener noreferrer" href="https://userway.org">יישומון נגישות האינטרנט של UserWay</a>  על ידי שרת נגישות ייעודי. התוכנה מאפשרת ל-{Siteurler} לשפר את תאימותה להנחיות הנגישות לתוכן אינטרנט (WCAG 2.1).
                <h2>הפעלת תפריט הנגישות</h2>
                ניתן להפעיל את תפריט הנגישות {Siteurler} על ידי לחיצה על סמל תפריט הנגישות המופיע בפינת העמוד. לאחר הפעלת תפריט הנגישות, אנא המתן רגע עד שתפריט הנגישות ייטען בשלמותו.
                <h2>כתב ויתור</h2>
                למה לינק ממשיכה במאמציה לשפר ללא הרף את הנגישות של האתר והשירותים שלה מתוך אמונה שזו המחויבות המוסרית הקולקטיבית שלנו לאפשר שימוש חלק, נגיש ובלתי מפריע גם לאלו מאיתנו עם מוגבלויות.
                <br /><br />
                במאמץ מתמשך לשפר ולתקן בעיות נגישות ללא הרף, אנו סורקים באופן קבוע את {Siteurler} עם סורק הנגישות UserWay לזהות ולתקן כל מחסום נגישות אפשרי באתר שלנו. למרות המאמצים שלנו להנגיש את כל הדפים והתכנים באתר {Siteurler}, ייתכן שחלק מהתוכן עדיין לא הותאם במלואו לתקני הנגישות המחמירים ביותר. ייתכן שהדבר נובע מכך שלא נמצא או זיהה את הפתרון הטכנולוגי המתאים ביותר.
                <h2>כאן בשבילך</h2>
                אם אתה נתקל בקושי עם תוכן כלשהו באתר {Siteurler} או זקוק לסיוע בכל חלק באתר שלנו, אנא צור איתנו קשר בשעות העבודה הרגילות כמפורט להלן ונשמח לסייע.
                <h2>צור קשר</h2>
                אם ברצונך לדווח על בעיית נגישות, יש לך שאלות כלשהן או זקוק לסיוע, אנא צור קשר עם תמיכת הלקוחות של למה לינק <a rel="noopener noreferrer" href="/contact">בדף יצירת הקשר שלנו</a>
                <br /><br />
              </div>
            </div>
        </div>
      </section>
    </div>
  </section>
</div>

</main>

</div>


  )
}

export default LegalAccessibility