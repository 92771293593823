import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa,Keywards,Siteurler,SiteTwitter,SiteImager } from '../constants/global'

const ReviewJson = `
{
    "@context": "https://schema.org/",
    "@type": "Product",
    "brand": {
      "@type": "Brand",
      "name": "Llama Link"
    },
    "description": "אירוח אתרים מתקדם, התקנת אתר וורדפרס במספר דקות, ניהול קל וידידותי, עזרה ותמיכה מקצועית סביב השעון",
    "sku": "42244284",
    "mpn": "244284",
    "image": "https://llamalink.net/cdn/city-domains-copyrighted-lq.png",
    "name": "הקמת אתר אינטרנט בקליק",
    "review": [{
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      },
      "author": {
        "@type": "Person",
        "name": "נתנאל אלוש"
      },
      "reviewBody": "שירות מעולה, יחס אישי ועזרה בעוד דברים שלא הבנתי בהם שהם מחוץ לשירות הניתן. ממש אלוף"
    },
    {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      },
      "author": {
        "@type": "Person",
        "name": "Eden Kermanian"
      },
      "reviewBody": "אחלה של שירות, מקצועי מאוד ונותן תמיכה רבה. ממליץ בחום!!"
    },
    {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      },
      "author": {
        "@type": "Person",
        "name": "Catalina Giurgea"
      },
      "reviewBody": "שירות אדיב ומקצועי, זמין לכל שאלה, מרוצה מאד, מומלץ בחום"
    }],
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "99",
      "bestRating": "100",
      "ratingCount": "38"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://llamalink.net/pricing",
      "priceCurrency": "ILS",
      "price": "30.00",
      "priceValidUntil": "2024-11-12",
      "itemCondition": "https://schema.org/UsedCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Llama Link"
      }
    }
  }
`


const PriceTabStyle =  `
.imagera{
    max-width:100%;
    height:auto;
}

/* Pricing Section by Dunia Blanter (www.idblanter.com) */
.pricing-section{font-family:'Google Sans',Arial,sans-serif;position:relative;overflow:hidden;display:block;margin:15px 0;line-height:1.7}
.pricing-section h3{font-size:1.5rem;margin:1rem 0 0}
.pricing-container{display:flex;max-width:1000px;margin:0 auto}
.pricing-tag{margin:.5rem 0 1rem;font-size:13px;display:inline-block;background:#333;color:#fff;padding:3px 15px;border-radius:20px}
a.blanter-order-btn{
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    background: #34e8ac!important;
    color: #1e2167;
    display: inline-block;
    padding: 7px 20px;
    border-radius: 9px;
    transition: all .3s ease-in-out;
  }
a.blanter-order-btn:hover{transform:scale(1.1)}
.pricing-blanter-column{background:#fff;padding:30px 15px;box-sizing:border-box;border-radius:10px;text-align:center;width:33%;margin:10px;box-shadow:0 7px 7px rgba(0,0,0,0.06);border:2px solid #fff;transition:all .3s ease-in-out}
.pricing-blanter-column ul{list-style:none;padding:10px 0;font-size:14px;line-height:2.2}
.pricing-blanter-column img{width:50px}
.pricing-section i{font-size:3rem}
.blanter-price{font-weight:700;font-size:22px}
.service-info{opacity:1.7}
.blanter-2-column .pricing-blanter-column{width:50%}
.pricing-blanter-column:nth-child(1) .blanter-price,.pricing-blanter-column:nth-child(1) i{color:#f87200} /* Change Color Code */
.pricing-blanter-column:nth-child(2) .blanter-price,.pricing-blanter-column:nth-child(2) i{color:#ff5483} /* Change Color Code */
.pricing-blanter-column:nth-child(3) .blanter-price,.pricing-blanter-column:nth-child(3) i{color:#5a74ff} /* Change Color Code */
.pricing-blanter-column:nth-child(1):hover{border-color:#f87200} /* Change Color Code */
.pricing-blanter-column:nth-child(2):hover{border-color:#ff5483} /* Change Color Code */
.pricing-blanter-column:nth-child(3):hover{border-color:#5a74ff} /* Change Color Code */
.pricing-blanter-column:nth-child(1) .pricing-tag,.pricing-blanter-column:nth-child(1) a.blanter-order-btn{background:#f87200} /* Change Color Code */
.pricing-blanter-column:nth-child(2) .pricing-tag,.pricing-blanter-column:nth-child(2) a.blanter-order-btn{background:#ff5483} /* Change Color Code */
.pricing-blanter-column:nth-child(3) .pricing-tag,.pricing-blanter-column:nth-child(3) a.blanter-order-btn{background:#5a74ff} /* Change Color Code */
@media screen and (max-width:580px){
.pricing-blanter-column,.blanter-2-column .pricing-blanter-column{width:auto}
.pricing-container{display:block}
}

@media (min-width: 768px){
   .portfolio-block.call-to-action h3 {
     margin-right: 0px;
 }
}    
    
    
    .pricing-section{
        font-family: Heebo, sans-serif;
    }    
    
    li{font-size: 16px;}
`


const Pricing = () => {

  return (
<div className='Pricing'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - מחירון אירוח אתרים</title>

        <meta name="description" content="מחירון אירוח אתרים וחבילות אירוח וורדפרס שבטוח יתאים לכם, כלים מתקדמים ותוספים שימושיים." />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- מחירון שירותי אינטרנט"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="מחירון אירוח אתרים וחבילות אירוח וורדפרס שבטוח יתאים לכם, כלים מתקדמים ותוספים שימושיים." /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="מחירון אירוח אתרים וחבילות אירוח וורדפרס שבטוח יתאים לכם, כלים מתקדמים ותוספים שימושיים." />
        <meta name="twitter:image" content={SiteImager} />

        <script type="application/ld+json">{ReviewJson}</script>
        <style>{PriceTabStyle}</style>
</Helmet>
{/* content goes here */}


<main data-aos="fade" data-aos-once="true" className="page lanidng-page">
  <section className="photo-gallery" />
  <section className="portfolio-block call-to-action border-bottom backbutpc" style={{width: '100%', paddingTop: 80, paddingBottom: 40, marginTop: '-8px', background: '#f4f5f9'}}>
    <div className="container" style={{paddingLeft: 10, paddingRight: 10}}>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col" style={{marginBottom: 60}}>
          <h1 style={{color: 'rgb(35,44,90)', fontSize: 41, textAlign: 'center', marginBottom: 40, fontFamily: 'Heebo, sans-serif'}}><strong>מחירון אירוח אתרים <br/> עם כל מה שהאתר שלכם צריך!</strong><br /><br /></h1>
          <div className="row">
            <div className="col-xl-12">
              <div className="pricing-section">
                <div className="pricing-container">
                  <div className="pricing-blanter-column">
                    <img src={AppCDNa+"assets/img/webp/basic.webp"} className="imagera" alt="Basic" />
                    <h3>בסיסי</h3>
                    <div className="pricing-tag">לאתרים קטנים ודפי נחיתה</div>
                    <div className="blanter-price">₪30 לחודש</div>
                    <a className="blanter-order-btn" style={{marginTop: 10}} onClick="subscribeToPack()" href="/signup">התחילו עכשיו</a>
                    <div className="service-info">
                      <ul>
                        <hr />
                        <li>ללא הגבלת תעבורה</li>
                        <hr />
                        <li>עזרה ותמיכה מאיתנו</li>
                        <hr />
                        <li><strong>5GB</strong> נפח אחסון (SSD מהיר)</li>
                        <hr />
                        <li>עד <strong>אתר אחד</strong> בחשבון</li>
                        <hr />
                        <li>כתובת דוא"ל עסקית אחת</li>
                        <hr />
                        <li>גיבוי כל יום</li>          
                        <hr />
                        <li>תעודת אבטחה SSL</li>
                        <hr />
                        <li>הגנת אתר עם Cloudflare</li>
                        <hr />
                        <li>ㅤ</li>
                        <hr />
                        <li>ㅤ</li>
                        <hr />
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-blanter-column">
                    <img src={AppCDNa+"assets/img/webp/personal.webp"} alt="Personal" />
                    <h3>מקצועי</h3>
                    <div className="pricing-tag">הפופולרי שלנו</div>
                    <div className="blanter-price">₪80 לחודש</div>
                    <a className="blanter-order-btn" style={{marginTop: 10}} onclick="subscribeToPack()" href="/signup">התחילו עכשיו</a>
                    <div className="service-info">
                      <ul>
                        <hr />
                        <li>ללא הגבלת תעבורה</li>
                        <hr />
                        <li>עזרה ותמיכה מאיתנו</li>
                        <hr />
                        <li><strong>15GB</strong> נפח אחסון (SSD מהיר)</li>
                        <hr />
                        <li>עד <strong>3 אתרים</strong> בחשבון</li>
                        <hr />
                        <li><strong>5</strong> כתובות דוא"ל עסקיות</li>
                        <hr />
                        <li>עד <strong>3 מסדי נתונים</strong></li>
                        <hr />
                        <li>גישה בFTP</li>
                        <hr />
                        <li>גיבוי כל יום</li>          
                        <hr />
                        <li>תעודת אבטחה SSL</li>
                        <hr />
                        <li>הגנת אתר עם Cloudflare</li>
                        <hr />
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-blanter-column">
                    <img src={AppCDNa+"assets/img/webp/developer.webp"} className="imagera" alt="Developer" />
                    <h3>מדברים עסקים</h3>
                    <div className="pricing-tag">לאתרי תוכן/חנות גדולים</div>
                    <div className="blanter-price">₪140 לחודש</div>
                    <a className="blanter-order-btn" style={{marginTop: 10}} onclick="subscribeToPack()" href="/signup">התחילו עכשיו</a>
                    <div className="service-info">
                      <ul>
                        <hr />
                        <li>ללא הגבלת תעבורה</li>
                        <hr />
                        <li>עזרה ותמיכה מאיתנו</li>
                        <hr />
                        <li><strong>40GB</strong> נפח אחסון (SSD מהיר)</li>
                        <hr />
                        <li>עד <strong>10 אתרים</strong> בחשבון</li>
                        <hr />
                        <li><strong>10</strong> כתובות דוא"ל עסקיות</li>
                        <hr />
                        <li>עד <strong>10 מסדי נתונים</strong></li>
                        <hr />
                        <li>גישה בFTP</li>
                        <hr />
                        <li>גיבוי כל יום</li>            
                        <hr />
                        <li>תעודת אבטחה SSL</li>
                        <hr />
                        <li>הגנת אתר עם Cloudflare</li>
                        <hr />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p style={{textAlign: 'center', color: 'rgb(0,0,0)', fontSize: 30, marginTop: 70}}>צריכים מפרט מותאם אישית? VPS?<br /><a className="btn btn-outline-primary" role="button" style={{color: 'rgb(255,255,255)', borderRadius: 10, background: 'rgb(90,63,255)', fontSize: 20, marginRight: 10, marginLeft: 10, borderColor: 'rgb(90,63,255)'}} href="vps">מעבר לחבילות השרתים שלנו</a><br /></p>
          <p style={{textAlign: 'center', color: 'rgb(0,0,0)', fontSize: 30}}><br /></p>
        </div>
      </div>
    </div>
  </section>
</main>




</div>


  )
}

export default Pricing