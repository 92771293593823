import React, {useEffect} from 'react';
import { scroller } from 'react-scroll';
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa, Siteurler,Keywards,SiteTwitter,SiteImager } from '../constants/global'
// import './styles/HomeStyle.css'
// import './scripts/HomeScript'
// import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';


const BackStyle = `
/*siteback*/
.herosectionarry{
      background: #1a1d78!important;
}

@media screen and (min-width: 600px) {
    .herosectionarry{
      background: url(`+AppCDNa+`/assets/img/webp/BackfullresL-optimized_16-9.webp) bottom / contain no-repeat, #1a1d78!important;
    }
}



/* .brands img {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%) brightness(90%) sepia(700%) hue-rotate(-180deg) saturate(300%) contrast(0.8); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(100%) brightness(90%) sepia(700%) hue-rotate(-180deg) saturate(300%) contrast(0.8); /* Microsoft Edge and Firefox 35+ */
  opacity: 75%;
} */

/* Disable grayscale on hover */
.brands img:hover {
  -webkit-filter: grayscale(0);
  filter: none;
  opacity: 100%;
}
`



const Home = () => {

  // jump to vps section after loading
  useEffect(() => {
    const hash = window.location.hash;

    if (hash && hash.startsWith('#')) {
      const elementId = hash.slice(1);

        setTimeout(() => {
          scroller.scrollTo(elementId, {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -50 // Adjust this value if needed to fine-tune the scroll position
          });
        }, 100);
    }
  }, []);


  return (

<div className='Home'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName}: פתרונות ענן שעסקים צריכים</title>


        <meta name="description" content="למה לינק(Llama Link) שירותי ענן מתקדמים. אירוח אתרים ואפליקציות, שרתים וירטואליים, מייל מותאם אישית ותוספים יעילים לאתרים. ניהול בקלות דרך מערכת מתקדמת ותמיכה תכנית מאנשי מקצוע מסביב לשעון." />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+": פתרונות אינטרנט שעסקים צריכים"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="למה לינק(Llama Link) שירותי ענן מתקדמים. אירוח אתרים ואפליקציות, שרתים וירטואליים, מייל מותאם אישית ותוספים יעילים לאתרים. ניהול בקלות דרך מערכת מתקדמת ותמיכה תכנית מאנשי מקצוע מסביב לשעון." /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="למה לינק(Llama Link) שירותי ענן מתקדמים. אירוח אתרים ואפליקציות, שרתים וירטואליים, מייל מותאם אישית ותוספים יעילים לאתרים. ניהול בקלות דרך מערכת מתקדמת ותמיכה תכנית מאנשי מקצוע מסביב לשעון." />
        <meta name="twitter:image" content={SiteImager} />

        <script src="/assets/js/homeNav.js"></script>
        <link href="/assets/css/HomeStyle.css" rel="stylesheet"/>
        <style>{BackStyle}</style>
</Helmet>

  <main className="page lanidng-page">
    <section className="portfolio-block block-intro herosectionarry" style={{paddingTop: 107, paddingBottom: 0, width: '100%', backgroundPositionX: 'center', backgroundPositionY: 'top', marginTop: '-12px', position: 'relative', zIndex: 1}}>
      <div style={{margin: '0 auto', bottom: 0}}>
        <div className="container" style={{paddingLeft: 10, paddingRight: 10, position: 'relative'}}>
          <div className="row" style={{marginLeft: 0, marginRight: 0}}>
            <div className="col-xl-5">
              <div className="_1-75em-bottom-margin">
                <div className="hero-stars-lockup-1">
                  <div className="stars-1">
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                    <div className="full-green-star" />
                  </div><div className="light-dimmed-text">5/5 • <a style={{color: '#fff'}} href="#review" rel="noopener noreferrer" className="white-text">ביקורות<span className="hidden-on-mobile"> ←</span></a></div></div>
              </div>
              <h1 className="bigusstext" style={{fontFamily: 'Heebo, sans-serif', fontSize: 53, color: '#fdfdfd', textAlign: 'right', paddingBottom: 0, marginBottom: 30, paddingTop: 0, borderColor: 'rgba(34,34,34,0)', lineHeight: '71.8px', textShadow: 'rgb(0 0 0 / 78%) 6px 6px 1px'}}><strong>פתרונות אינטרנט שהעסק שלך באמת צריך</strong><span style={{color: '#34e8ac'}}><strong>.</strong></span></h1>
              <p className="paragherotext" style={{color: '#fdfdfd', textAlign: 'right', fontFamily: 'Heebo, sans-serif', fontSize: 24}}>תקפיצו את העסק/פרויקט שלכם לאינטרנט עם אתר אינטרנט בקליק,<br /><strong>כתובות דוא"ל עסקיות, שרתים וירטואליים פרטיים</strong>&nbsp;והכל דרך ניהול ידידותי ומתקדם</p>
              <div className="row" style={{textAlign: 'center'}}>
                <div className="col-xl-11 offset-xl-0" style={{paddingRight: 0, paddingLeft: 0, textAlign: 'right'}}>
                  <a href="signup"><button className="btn btn-primary btnhm" data-bss-hover-animate="pulse" type="button" style={{fontSize: 30}}><strong>15 יום ראשונים חינם</strong></button></a>
                </div>
              </div>
            </div>
            <div className="col-xl-7" style={{paddingRight: 0, paddingLeft: 0, marginTop: 40, marginRight: 0}}><img alt="" className="invisibleimage lazyload" data-src={AppCDNa+'assets/img/webp/benefit%20.webp'} style={{width: '100%', marginTop: 10}} /></div>
          </div>
        </div>
      </div>
    </section>
    <section className="photo-gallery" />
    <div className="brands" style={{background: '#f7f8ff',paddingTop:'2rem',paddingBottom:'2rem'}}> 
    <div className="container">
    <h1 className="header-but-categ pb-5" style={{textAlign: 'center', color: '#19216c', fontFamily: 'Heebo, sans-serif', fontSize: '2.7rem'}}><strong>בין לקוחותינו העסקיים,<br/> גדולים וקטנים.</strong></h1>
    </div>
        <img alt="" data-src={AppCDNa+'assets/img/partners/ort-ready.png'} className="lazyload aos-init aos-animate" style={{width: 180}} data-aos="fade-up" data-aos-duration="1100" data-aos-once="true"/>
        <img alt="" data-src={AppCDNa+'assets/img/partners/wam-ready.png'} className="lazyload aos-init aos-animate" style={{width: 140}} data-aos="fade-up" data-aos-duration="800" data-aos-once="true"/>
        <img alt="" data-src={AppCDNa+'assets/img/partners/tlv_ready.png'} className="lazyload aos-init aos-animate" style={{width: 180}} data-aos="fade-up" data-aos-duration="1100" data-aos-once="true"/>
        <img alt="" data-src={AppCDNa+'assets/img/partners/ab_ready.png'} className="lazyload aos-init aos-animate" style={{width: 180}} data-aos="fade-up" data-aos-duration="1100" data-aos-once="true"/>
        <img alt="" data-src={AppCDNa+'assets/img/partners/maof1_ready.png'} className="lazyload aos-init aos-animate" style={{width: 180}} data-aos="fade-up" data-aos-duration="1100" data-aos-once="true"/>
        <img alt="" data-src={AppCDNa+'assets/img/partners/trv_ready.png'} className="lazyload aos-init aos-animate" style={{width: 180}} data-aos="fade-up" data-aos-duration="1100" data-aos-once="true"/>
    </div>
    <section className="portfolio-block call-to-action" id="cloud" style={{width: '100%', padding: 0, paddingTop: 50, paddingBottom: 90, borderWidth: '0px!important'}}>
      <div className="container">
        <h1 className="header-but-categ header-cat-mob block_services-title">הפתרונות שלנו לעסקים</h1>
        <div className="row">
          <div className="col-xl-12" style={{paddingRight: 8, paddingLeft: 8, paddingTop: 58}}>
            <div className="row" style={{marginRight: 0, marginLeft: 0}}>
              <div className="col-md-4 col-xl-3">
                <NavLink to="websites" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={AppCDNa+'/assets/img/icon/domain.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>אירוח אתרי אינטרנט</strong></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4 col-xl-3">
                <NavLink to="vps" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={AppCDNa+'/assets/img/icon/server.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>שרתים וירטואליים</strong><br /></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4 col-xl-3">
                <NavLink to="email" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={AppCDNa+'/assets/img/icon/email-sign.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>אימייל עסקי</strong><br /></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4 col-xl-3">
                <NavLink to="sms" className="service-linker hoveranim">
                  <div className="card special-skill-item border-0 service-carder" data-aos="fade-up" data-aos-duration={800} data-aos-once="true">
                    <div className="card-header bg-transparent border-0" />
                    <div className="card-body"><img alt="" data-src={AppCDNa+'/assets/img/icon/messaging-.svg'} className="service-imager lazyload" />
                      <h1 className="services-texter"><strong>SMS עסקי</strong></h1>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

<section className="testimonials" id="review">
  <div className="container">
    <div className="title">
      <h5>ביקורות אמיתיות</h5>
      <h1 className="header-but-categ" style={{color: '#19216c', fontFamily: 'Heebo, sans-serif', fontSize: 55}}><strong>מה אומרים עלינו?</strong></h1>
    </div>
    <div className="owl-carousel owl-theme testi">

      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/netan.png" alt="" />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>נתנאל אלוש</p>
            <span>ייעוץ פיננסי</span>
          </div>
        </div>
        <p>שירות מעולה, יחס אישי ועזרה בעוד דברים שלא הבנתי בהם שהם מחוץ לשירות הניתן. ממש אלוף</p>
        <div className="icon">
        </div>
      </div>

      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/avizo.png" alt="" />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>אביזוהר הראל</p>
            <span>לינה וצימרים</span>
          </div>
        </div>
        <p>העבודה עם אושר הייתה פשוט מרתקת. הוא ידע להקשיב לצרכים ולדייק בהכנת האתר. אך מעבר להכל זה האנושיות והשירות שאחרי. אינספור פעמים פניתי לעזרה ולשיפורים, ואושר בשקט הנפשי שלו ובאדיבותו הענקית פתר בעיות ושיפר את חוויית השימוש באתר. ממליץ לכל מי שמחפש .מקצוענות ואנושיות</p>
        <div className="icon">
        </div>
      </div>


      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/rachelg.png" alt="" />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>רחל גץ-סלומון</p>
            <span>חוקרת אופנה ותרבות</span>
          </div>
        </div>
        <p>העבודה על האתר הייתה מקצועית מאוד! לאורך כל הדרך התקשורת הייתה זמינה ומיידית, וקשובה לכל בקשה (או אפילו גחמה) שלי.
בסבלנות ובנעימות קיבלתי הסבר לכל דבר, הדרכה מדוקדקת וליווי בכל צעד שעשיתי בהבנת תפעול האתר.
אני ממליצה מאוד מאוד לעבוד עם למה לינק, ברור לי שעבור כל פרויקט נוסף שאצטרך זאת הכתובת עבורי.
בהצלחה,
ד"ר רחל גץ סלומון</p>
        <div className="icon">
        </div>
      </div>




      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/osher.png" alt />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>אושר חיים גליק</p>
            <span>חברת תוכנה ואוטומציה</span>
          </div>
        </div>
        <p>שירות יוצא מן הכלל! עזרו עד שהתוכנה שלנו רצה</p>
        <div className="icon">
        </div>
      </div>


      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/tzameret.png" alt />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>צמרת איילה</p>
            <span>מכירה ושיווק</span>
          </div>
        </div>
        <p>ואוו ואו אין שירות כזה, מרגישה VIP! תודה רבה לכם</p>
        <div className="icon">
        </div>
      </div>




      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/roy.png" alt />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>רועי זלצמן</p>
            <span>אמן חושים</span>
          </div>
        </div>
        <p>למה לינק הינה חברה של תותחים עם שירות מדהים שלא רואים במקומות אחרים. לקחתי אצלם מספר שרתים וחיברתי אליהם לפחות 10 לקוחות שגם כן מאוד מרוצים</p>
        <div className="icon">
        </div>
      </div>


      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/trimal.png" alt="" />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>תריסי מלכה</p>
            <span>חברת תריסים וחלונות</span>
          </div>
        </div>
        <p>חייב לציין אושר אחד מתוך מיליון אנשים שאני אפגוש
שילוב של נשמה טהורה גאונות אלוף במחשבים רוגע נתינה ועוד הרבה תכונות טובות ביחד
ממליץ עליך בחום ושמח שהכרתי אותך</p>
        <div className="icon">
        </div>
      </div>
      

      <div className="item">
        <div className="profile">
          <img src="/assets/img/clients/idmohr.jpg" alt />
          <div className="information">
            <div className="stars">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
            <p>עידית מור</p>
            <span>ייעוץ מערכות</span>
          </div>
        </div>
        <p>ממליצה בחום על אושר ושרותי תחזוקת האתר שלו. פניתי בעקבות הודעות שגיאה שהתקבלו באתרים. אושר היה זמין, נגיש, שירותי ופתר את הבעיות במהירות ובמקצועיות. שמחתי לפגוש איש מקצוע מעולה והוגן אחרי שנים רבות של אכזבות בתחום. אין ספק שנמשיך לעבוד יחד גם בעתיד ושאמליץ עליו לכל מי שזקוק לשירות דומה.</p>
        <div className="icon">
        </div>
      </div>


    </div>

    <div className="row" style={{paddingTop: 35}}>
        <div className="col-xl-12" style={{textAlign: 'center'}}><a href="https://apbs.link/llamalink-google" target='_blank'><button className="btn btn-primary bigbtn" data-bss-hover-animate="pulse" type="button" style={{background: 'linear-gradient(113deg, rgb(52, 232, 172), rgb(72, 135, 254))', borderRadius: 15, fontSize: 20, marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(25 33 108)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8, textAlign: 'center'}}><strong>לכל הביקורות בגוגל&nbsp;<i className="fab fa-google" /></strong></button></a></div>
      </div>
  </div>
</section>

    <section className="portfolio-block call-to-action" style={{width: '100%', paddingBottom: 0, borderWidth: 0, marginTop: 0, paddingTop: 0}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-5" style={{paddingRight: 0, paddingTop: 30, paddingLeft: 33}}><img alt="" data-src={AppCDNa+'assets/img/webp/yosi_im_mahshev_rdy.webp'} className="lazyload" style={{width: '100%'}} /></div>
          <div className="col-xl-6" data-aos="zoom-in-up" data-aos-duration={800} data-aos-once="true" style={{paddingTop: 116}}>
            <h1 className="header-but-categ" style={{textAlign: 'right', color: '#19216c', fontFamily: 'Heebo, sans-serif', fontSize: 55}}><strong>ניהול פשוט וקל</strong>.</h1>
            <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 25, fontFamily: 'Heebo, sans-serif'}}>שימוש בשירותי ענן עם פאנל ניהול ידידותי שנותן לכם מידע חשוב, יכולות הגדרה קלילות ועוד המון.
            </p></div>
        </div>
      </div>
    </section>



    {/* <section class="portfolio-block call-to-action" style="width: 100%;padding-bottom: 0px;border-width: 0px;margin-top: 0px;padding-top: 0px;">
      <div class="container" style="margin-top: 30px;margin-bottom: 30px;">
          <div class="row">
              <div class="col-xl-5" style="padding-right: 0px;padding-top: 30px;padding-left: 33px;"><img alt="" src="<?php echo $cdnurler;?>assets/img/webp/wp-plugins-nsz2.webp" style="width: 100%;"></div>
              <div class="col-xl-6" data-aos="zoom-in-up" data-aos-duration="800" data-aos-once="true" style="padding-top: 116px;">
                  <h1 class="header-but-categ" style="text-align: right;color: #19216c;font-family: Heebo, sans-serif;font-size: 55px;"><strong>מאות תוספים</strong>.</h1>
                  <p style="text-align: right;color: rgb(62,62,62);font-size: 25px;font-family: Heebo, sans-serif;">מאות תוספים שימושיים שיאפשרו לכם לבנות ולנהל אתר בקלות ובראש שקט.
              </div>
          </div>
      </div>
  </section> */}



    {/* <section data-aos="fade-up" data-aos-duration={800} data-aos-delay={250} data-aos-once="true" className="portfolio-block call-to-action" style={{width: '100%', padding: 0, paddingTop: 100, paddingBottom: 120, marginTop: '-1px', borderWidth: '0px!important', background: `url("${AppCDNa}/assets/img/webp/features-section.webp")`}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-12" style={{paddingRight: 8, paddingLeft: 8}}>
            <h1 className="header-but-categ" style={{textAlign: 'right', color: 'rgb(42,42,42)', fontFamily: 'Heebo, sans-serif', fontSize: 55, borderColor: 'rgb(255,255,255)', marginTop: 0, paddingBottom: 20}}><strong>עם פיצ'רים כאלה&nbsp;&nbsp;</strong><br />אפשר לישון בשקט.<br /></h1>
            <div className="row" style={{marginRight: 0, marginLeft: 0}}>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={100} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-ios-bolt" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>הגנה ממתקפות מניעת שירות</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={150} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-speedometer" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>תשתיות חדשניות ומהירות</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={200} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-android-cloud-done" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>סריקות אבטחה מתקדמות</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={300} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-upload" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>גיבוי אתרים על בסיס יומי</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="fade-up" data-aos-duration={600} data-aos-delay={250} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-ios-pulse-strong" style={{background: '#5583ff'}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}><strong>זמינות מערכת גבוהה, 99%</strong><br /></h3><a href="https://status.llamalink.net" style={{color: 'rgb(90,63,255)'}} target="_blank" rel="noopener noreferrer">מצב המערכות שלנו&nbsp;<span style={{textDecoration: 'underline'}}>כאן</span><br /></a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card special-skill-item border-0 card-colorer" data-aos="zoom-in" data-aos-duration={700} data-aos-delay={350} data-aos-once="true" style={{height: 250, borderRadius: 10}}>
                  <div className="card-header bg-transparent border-0"><i className="icon ion-ios-heart" style={{background: '#5583ff', paddingTop: 5}} /></div>
                  <div className="card-body">
                    <h3 className="card-title card-titless" style={{marginRight: 0, fontFamily: 'Heebo, sans-serif'}}>תמיכה טכנית מקצועית בכמה ערוצים</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
  </main>
  <section data-bss-parallax-bg="true" className="border-top portfolio-block website gradient" style={{marginTop: '-1px', background: '#ffffff'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-7">
          <h1 style={{textAlign: 'right', color: 'rgb(33,33,33)', fontFamily: 'Heebo, sans-serif', fontSize: 35}}><strong>עדיין כאן? קבלו 15 יום חינם</strong>, נסו אותנו<br /></h1>
        </div>
        <div className="col-xl-4" style={{textAlign: 'right'}}><a href="signup"><button className="btn btn-primary bigbtn" data-bss-hover-animate="pulse" type="button" style={{background: 'linear-gradient(89deg,#4885ff,#5b54ff)', borderRadius: 15, fontSize: 20, textShadow: '0px 0px 8px rgba(0,0,0,0.25)', marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8, textAlign: 'center'}}><strong>15 יום ראשונים חינם</strong></button></a></div>
      </div>
    </div>
  </section>

</div>


  )
}

export default Home