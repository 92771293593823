import React from 'react'
import { Helmet } from 'react-helmet';

// import { AppName ,AppLogo } from '../constants/global'
import { NavLink } from 'react-router-dom'

const LegalStyle = `
.legalitem {
font-size: 20px; 
color: rgb(40,40,40);
}

.legalitem.active {
  color: #5959ff;
}




.legalmenuer{
  position: sticky;
  // background: #f1f1f1;
  padding: 6px;
  border-radius: 6px;
  top: 80px;
}
`

const LegalMenu = () => {
  return (
<>
<Helmet>
<style>{LegalStyle}</style>
</Helmet>

<div className="col-xl-2 containermenuer">

  <div className='legalmenuer'>
  <p><NavLink to="/legal/privacy-policy" className="legalitem"><strong>מדיניות הפרטיות</strong></NavLink></p>
  <p><NavLink to="/legal/terms-of-service" className="legalitem"><strong>תנאי שימוש</strong><br /></NavLink></p>
  <p><NavLink to="/legal/accessibility" className="legalitem"><strong>נגישות האתר</strong><br /></NavLink></p>
  <p><a href="https://www.dmca.com/compliance/llamalink.net" className="legalitem" target="_blank" rel="noopener noreferrer"><strong>זכויות יוצרים</strong><br /></a></p>
  <p><NavLink to="/legal/askforinfo" className="legalitem"><strong>בקשת מידע</strong><br /></NavLink></p>
  </div>
</div>
</>

  )
}

export default LegalMenu