import React from 'react'
import { Helmet } from 'react-helmet';
import { AppName, AppCDNa,Keywards,Siteurler,SiteTwitter,SiteImager } from '../constants/global'

const Websites = () => {

  return (
<div className='Websites'>
{/* HEAD content goes here */}
<Helmet>
        <title>{AppName} - אירוח אתרים מתקדם</title>
        
        <meta name="description" content="שירותי ענן מתקדמים. אירוח אתרים ואפליקציות, שרתים וירטואליים, מייל מותאם אישית ותוספים יעילים לאתרים. ניהול בקלות דרך מערכת מתקדמת ותמיכה תכנית מאנשי מקצוע מסביב לשעון." />
        <meta name="keywords" content={Keywards} />
        {/* OG Meta Tags to improve the way the post looks when you share the page on LinkedIn, Facebook, Google */}
        <meta property="og:site_name" content={AppName} /> {/* website name */}
        <meta property="og:site" content={Siteurler} /> {/* website link */}
        <meta property="og:title" content={AppName+"- אירוח אתרים מתקדם"} /> {/* title shown in the actual shared post */}
        <meta property="og:description" content="שירותי ענן מתקדמים. אירוח אתרים ואפליקציות, שרתים וירטואליים, מייל מותאם אישית ותוספים יעילים לאתרים. ניהול בקלות דרך מערכת מתקדמת ותמיכה תכנית מאנשי מקצוע מסביב לשעון." /> {/* description shown in the actual shared post */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={SiteTwitter} />
        <meta name="twitter:title" content={AppName} />
        <meta name="twitter:description" content="שירותי ענן מתקדמים. אירוח אתרים ואפליקציות, שרתים וירטואליים, מייל מותאם אישית ותוספים יעילים לאתרים. ניהול בקלות דרך מערכת מתקדמת ותמיכה תכנית מאנשי מקצוע מסביב לשעון." />
        <meta name="twitter:image" content={SiteImager} />
        
</Helmet>
{/* content goes here */}



<div>
  <main className="page lanidng-page">
    <section className="portfolio-block block-intro herosectionarry" style={{paddingTop: 110, paddingBottom: 0, width: '100%', backgroundPositionX: 'center', backgroundPositionY: 'top', marginTop: '-12px', background: `linear-gradient(-117deg, #ffffff, rgba(255,255,255,0)) #ffffff`, position: 'relative', zIndex: 1}}>
      <div style={{margin: '0 auto', bottom: 0}} className="pb-5">
        <div className="container" style={{paddingLeft: 10, paddingRight: 10, position: 'relative'}}>
          <div className="row" style={{marginLeft: 0, marginRight: 0}}>
            <div className="col-xl-5">
              <h1 className="bigusstext" style={{fontFamily: 'Heebo, sans-serif', fontSize: 71, color: '#132078', textAlign: 'right', paddingBottom: 0, marginBottom: 30, paddingTop: 0, borderColor: 'rgba(34,34,34,0)', lineHeight: '98.8px'}}><strong>בנו אתר אינטרנט בקליק</strong><span style={{color: '#4982ff'}}><strong>.</strong></span></h1>
              <p className="paragherotext" style={{color: 'rgb(28,37,71)', textAlign: 'right', fontFamily: 'Heebo, sans-serif', fontSize: 27}}><strong>בנו אתר/חנות אינטרנט סופר מהירים, </strong><br /><strong>חברו את הכלים החשובים לאתר שלכם.</strong>&nbsp;והכל דרך ניהול ידידותי ומתקדם</p>
              <div className="row" style={{textAlign: 'center'}}>
                <div className="col-xl-11 offset-xl-0" style={{paddingRight: 0, paddingLeft: 0, textAlign: 'right'}}>
                  <a href="signup"><button className="btn btn-primary bigbtn" data-bss-hover-animate="pulse" type="button" style={{background: 'linear-gradient(113deg,#34e8ac,#4887fe)', borderRadius: 15, fontSize: 30, textShadow: '0px 0px 8px rgba(0,0,0,0.25)', marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8}}><strong>15 יום ראשונים חינם</strong></button></a>
                </div>
              </div>
            </div>
            <div className="col-xl-7" style={{paddingRight: 0, paddingLeft: 0, marginTop: 40, marginRight: 0}}><img alt="" className="invisibleimage lazyload" data-src={AppCDNa+"assets/img/panel-ssh1.png"} style={{width: '100%', marginTop: 10}} /></div>
          </div>
        </div>
      </div>
    </section>
    <section className="photo-gallery" />
    <div className="brands" style={{background: '#f7f7f7'}}>
        <img alt="" data-src={AppCDNa+"assets/img/elementor12.png"} className="lazyload" style={{width: 180}} />
        <img alt="" data-src={AppCDNa+"assets/img/webp/wordpress1.webp"} className="lazyload" style={{width: 180}} />
        <img alt="" data-src={AppCDNa+"assets/img/webp/cloudflare.webp"} className="lazyload" style={{width: 180}} />
        <img alt="" data-src={AppCDNa+"assets/img/litespeed-logo.svg"} className="lazyload" style={{width: 180}} />
        <img alt="" data-src={AppCDNa+"assets/img/Redis_Logo.svg.png"} className="lazyload" style={{width: 180}} /></div>
    <section className="portfolio-block call-to-action" style={{width: '100%', paddingBottom: 70, borderWidth: 0, marginTop: 0, paddingTop: 0}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-6" style={{paddingTop: 30}}><img alt="" className="img-toppdaew lazyload" data-src={AppCDNa+"assets/img/webp/wp-fast-af-nsz11.webp"} style={{width: '100%'}} /></div>
          <div className="col-xl-6 sectiontexter">
            <h1 className="header-but-categ header-cat-mob" style={{textAlign: 'right', color: '#19216c', fontFamily: 'Heebo, sans-serif', fontSize: 55}}><strong>אתר פשוט מהיר!</strong></h1>
            <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 25, fontFamily: 'Heebo, sans-serif'}}>בעזרת מטמון חכם, איזון עומסים והפעלת האתר בריבוי שרתים האתר שלכם יטוס כמו טיל.<br /></p>
          </div>
        </div>
      </div>
    </section>
    <section data-aos="fade-up" data-aos-duration={800} data-aos-delay={250} data-aos-once="true" className="portfolio-block call-to-action border-bottom" style={{width: '100%', padding: 0, paddingTop: 39, paddingBottom: 100, marginTop: '-1px', borderWidth: '0px!important', background: `url(`+AppCDNa+`assets/img/webp/features-section.webp) center / cover no-repeat`}}>
      <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-12" style={{paddingRight: 8, paddingLeft: 8}}>
            <h1 className="header-but-categ" style={{textAlign: 'right', color: 'rgb(25 33 108)', fontFamily: 'Heebo, sans-serif', fontSize: 55, borderColor: 'rgb(255,255,255)', marginTop: 0, paddingBottom: 20}}><strong>עם פיצ'רים כאלה&nbsp;&nbsp;</strong><br />אפשר לישון בשקט.<br /></h1>
            <div className="row" style={{marginRight: 0, marginLeft: 0}}>
              <div className="col-xl-4">
                <div className="card border-0 glass-card-colorer" style={{margin: 10}}>
                  <div className="card-body" style={{margin: 0}}>
                    <img alt="" data-src={AppCDNa+"assets/img/icons/speed.svg"} width="80px" className="lazyload" />
                    <h4 className="card-title">אתר מהיר בעומסים</h4>
                    <p className="card-text">איזון עומסים חכם שיעשה את האתר שלכם מהיר בכל מצב.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card border-0 glass-card-colorer" style={{margin: 10}}>
                  <div className="card-body" style={{margin: 0}}>
                    <img alt="" data-src={AppCDNa+"assets/img/icons/security-ssl.svg"} width="80px" className="lazyload" />
                    <h4 className="card-title">תעודת SSL מתנה</h4>
                    <p className="card-text">הפקת תעודת SSL חינמית ואוטומטית לאתר שלכם.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card border-0 glass-card-colorer" style={{margin: 10}}>
                  <div className="card-body" style={{margin: 0}}>
                    <img alt="" data-src={AppCDNa+"assets/img/icons/wordpress.svg"} width="80px" className="lazyload" />
                    <h4 className="card-title">ניהול וורדפרס בקליק</h4>
                    <p className="card-text">התקנת וורדפרס בלחיצת כפתור, ניהול ועדכון ישירות מהחשבון שלכם.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card border-0 glass-card-colorer" style={{margin: 10}}>
                  <div className="card-body" style={{margin: 0}}>
                    <img alt="" data-src={AppCDNa+"assets/img/icons/synchronize.svg"} width="80px" className="lazyload" />
                    <h4 className="card-title">גיבוי כל יום</h4>
                    <p className="card-text">גיבוי האתר על בסיס יום יומי, שחזור גיבויים בקליק במערכת.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card border-0 glass-card-colorer" style={{margin: 10}}>
                  <div className="card-body" style={{margin: 0}}>
                    <img alt="" data-src={AppCDNa+"assets/img/icons/medium-connection.svg"} width="80px" className="lazyload" />
                    <h4 className="card-title">זמינות אתרים גבוהה, 99%</h4>
                    <p className="card-text">התשתיות שלנו יציבות ועוברות ניטור ובדיקה 24/7.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card border-0 glass-card-colorer" style={{margin: 10}}>
                  <div className="card-body" style={{margin: 0}}>
                    <img alt="" data-src={AppCDNa+"assets/img/icons/technical-support.svg"} width="80px" className="lazyload" />
                    <h4 className="card-title">תמיכה אנושית מכל הלב</h4>
                    <p className="card-text">זמינים לעזרתכם בצ'אט, בואצאפ ובמייל. איפה שנוח לכם</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container" style={{marginTop: 30, marginBottom: 30}}>
        <div className="row">
          <div className="col-xl-5" style={{paddingRight: 0, paddingTop: 30, paddingLeft: 33}}><img alt="" data-src={AppCDNa+"assets/img/webp/wp-plugins-nsz11.webp"} className="lazyload" style={{width: '100%'}} /></div>
          <div className="col-xl-6" data-aos="zoom-in-up" data-aos-duration={800} data-aos-once="true" style={{paddingTop: 116}}>
            <h1 className="header-but-categ" style={{textAlign: 'right', color: '#19216c', fontFamily: 'Heebo, sans-serif', fontSize: 55}}><strong>מאות תוספים</strong>.</h1>
            <p style={{textAlign: 'right', color: 'rgb(62,62,62)', fontSize: 25, fontFamily: 'Heebo, sans-serif'}}>מאות תוספים שימושיים שיאפשרו לכם לבנות ולנהל אתר בקלות ובראש שקט.
            </p></div>
        </div>
      </div> */}
    </section>        
    {/* <section class="portfolio-block call-to-action border-bottom" style="width: 100%;padding-bottom: 31px;margin-top: -4px;padding-top: 80px;border-width: 0px!important;border-color: rgb(33, 37, 41);border-top-color: rgb(33,;border-right-color: 37,;border-bottom-color: 41);border-left-color: 37,;background: rgba(255,255,255,0);">
      <div class="container" style="margin-top: 30px;margin-bottom: 30px;">
          <div class="row">
              <div class="col-xl-5 offset-xl-0" data-aos="fade-up" data-aos-duration="500" data-aos-once="true" style="padding-top: 50px;padding-right: 40px;">
                  <h1 class="header-but-categ" style="text-align: right;color: #19216c;font-family: Heebo, sans-serif;font-size: 45px;">להקים אתר בקל,<br><strong>לנהל עוד יותר.</strong></h1>
                  <p style="text-align: right;color: rgb(62,62,62);font-size: 24px;font-family: Heebo, sans-serif;">להקים אתרי אינטרנט במספר לחיצות כפתור.
              </div>
              <div class="col-xl-7 offset-xl-0"><video autoplay="" loop="" muted="" width="100%">
                      <source src="<?php echo $cdnurler;?>assets/video/pannel-prev3.mp4" type="video/mp4">
                  </video></div>
          </div>
      </div>
  </section> */}
  </main>
  <section data-bss-parallax-bg="true" className="portfolio-block website gradient" style={{marginTop: '-1px', background: '#ffffff'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-7">
          <h1 style={{textAlign: 'right', color: 'rgb(33,33,33)', fontFamily: 'Heebo, sans-serif', fontSize: 35}}><strong>עדיין כאן? קבלו 15 יום חינם</strong>, נסו אותנו<br /></h1>
        </div>
        <div className="col-xl-4" style={{textAlign: 'right'}}><a href="signup"><button className="btn btn-primary bigbtn" data-bss-hover-animate="pulse" type="button" style={{background: 'linear-gradient(113deg,#34e8ac,#4887fe)', borderRadius: 15, fontSize: 20, textShadow: '0px 0px 8px rgba(0,0,0,0.25)', marginBottom: 0, marginLeft: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 30, paddingLeft: 30, color: 'rgb(255,255,255)', borderWidth: 0, borderColor: 'rgba(0,0,0,0)', fontFamily: 'Heebo, sans-serif', marginTop: 8, textAlign: 'center'}}><strong>15 יום ראשונים חינם</strong></button></a></div>
      </div>
    </div>
  </section>
</div>



</div>


  )
}

export default Websites